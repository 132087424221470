import { useContext, FC, Ref, Dispatch, SetStateAction, MouseEventHandler, ReactNode } from 'react';
import { UserContext } from '../UserContext';

import classnames from 'classnames';
import UserIcon from './UserIcon';
import HamburgerMenu from 'dibs-icons/exports/legacy/HamburgerMenu';
import Close from 'dibs-icons/exports/legacy/Close';
import { TRACK_ACTIONS, trackNavClick } from '../helpers/tracking';

import styles from './styles/ResponsiveMenuToggles.scss';
/*
 Renders the user dropdown for the dealer and internal nav bar.
 The hamburger menu renders nav bar links in mobile/responsive
 views. This should be hidden when custom banner text is
 present.
*/

const Circle: FC<$TSFixMe> = () => (
    <svg className={styles.notificationIndicator} viewBox="0 0 20 20">
        <circle cx="10" cy="10" r="10" />
    </svg>
);

type MenuToggleProps = {
    menuRef?: Ref<HTMLLIElement>;
    menuType: 'userMenuToggle' | 'navMenuToggle';
    label: string;
    expanded?: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
};

const MenuToggle: FC<MenuToggleProps> = ({
    menuRef,
    menuType,
    label,
    expanded,
    onClick,
    children,
}) => (
    <li ref={menuRef} className={classnames(styles.responsiveMenuItem, styles.separatorOnMany)}>
        <button
            data-tn={menuType}
            className={styles[menuType]}
            aria-label={`toggle ${label}`}
            aria-expanded={expanded}
            onClick={onClick}
        >
            {children}
        </button>
    </li>
);

type Props = {
    menuState: {
        navMenu: boolean;
        userMenu: boolean;
    };
    isMasquerading?: boolean;
    isDirectLogin: boolean;
    userMenuButtonRef: Ref<HTMLLIElement>;
    setMenuState: Dispatch<SetStateAction<{ userMenu: boolean; navMenu: boolean }>>;
    isCustomHeader?: boolean;
};

const ResponsiveMenuToggles: FC<Props> = ({
    isMasquerading,
    isDirectLogin,
    userMenuButtonRef,
    menuState,
    setMenuState,
    isCustomHeader,
}) => {
    const { hasNotifications } = useContext(UserContext);
    return (
        <ul
            className={classnames({
                [styles.masqueradeLabel]: isMasquerading,
                [styles.userLabel]: !isMasquerading,
            })}
        >
            <MenuToggle
                label="user menu"
                menuRef={userMenuButtonRef}
                menuType="userMenuToggle"
                expanded={menuState.userMenu}
                onClick={() => {
                    trackNavClick({
                        action: TRACK_ACTIONS.MY_ACCOUNT,
                        label: TRACK_ACTIONS.MY_ACCOUNT,
                    });
                    setMenuState(prev => ({ navMenu: false, userMenu: !prev.userMenu }));
                }}
            >
                {menuState.userMenu ? (
                    <Close className={styles.navMenuIcon} />
                ) : (
                    <div className={styles.iconContainer}>
                        <UserIcon isMasquerading={!!isMasquerading} isDirectLogin={isDirectLogin} />
                    </div>
                )}
            </MenuToggle>
            {isCustomHeader ? null : (
                <MenuToggle
                    menuType="navMenuToggle"
                    label="navigation menu"
                    onClick={() => {
                        setMenuState(prev => ({ userMenu: false, navMenu: !prev.navMenu }));
                    }}
                >
                    {hasNotifications && !menuState.navMenu && <Circle />}
                    {menuState.navMenu ? (
                        <Close className={styles.navMenuIcon} />
                    ) : (
                        <HamburgerMenu className={styles.navMenuIcon} />
                    )}
                </MenuToggle>
            )}
        </ul>
    );
};

export default ResponsiveMenuToggles;
