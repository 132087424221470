import PropTypes from 'prop-types';
import styles from './styles/Badge.scss';

const paddingPropToStyle = padding =>
    padding
        ? Object.keys(padding).reduce((acc, curr) => {
              const caps = curr.charAt(0).toLocaleUpperCase() + curr.slice(1);
              acc[`padding${caps}`] = padding[curr] + 'px';
              return acc;
          }, {})
        : null;

export const Badge = ({ content, dataTn, padding }) => (
    <span className={styles.notificationBadge} style={paddingPropToStyle(padding)} data-tn={dataTn}>
        {content}
    </span>
);

Badge.propTypes = {
    content: PropTypes.node,
    dataTn: PropTypes.string,
    padding: PropTypes.shape({
        top: PropTypes.number,
        right: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
    }),
};
