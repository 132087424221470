import * as tracking from 'dibs-tracking';

const CATEGORY = 'Browser Notifications';

export function trackTabAlertAction(action: 'Displayed' | 'Clicked'): void {
    tracking.trackEvent({
        category: CATEGORY,
        action: `Browser Tab Alert ${action}`,
        label: 'Browser Tab Alert',
    });
}

export function trackModalPermissionAction(action: 'Displayed' | 'Clicked', label: string): void {
    tracking.trackEvent({
        category: CATEGORY,
        action: action === 'Displayed' ? 'Modal Displayed' : 'Click pre-permission modal',
        label,
    });
}

export function trackRejectionBannerAction(action: 'Displayed' | 'Clicked', label: string): void {
    tracking.trackEvent({
        category: CATEGORY,
        action:
            action === 'Displayed'
                ? 'Permissions Rejected Banner Displayed'
                : 'Click permissions rejected banner',
        label,
    });
}

export function trackNotificationSystemPrompt(label: 'Allow' | 'Reject'): void {
    tracking.trackEvent({
        category: CATEGORY,
        action: 'Click System prompt',
        label,
    });
}

export function trackBrowserNotification(label: 'Message' | 'Order' | 'Offer' | 'Sign Up'): void {
    tracking.trackEvent({
        category: CATEGORY,
        action: 'Display Notification',
        label,
    });
}
