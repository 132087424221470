/**
 * @generated SignedSource<<46304cd18c65c01d0547cabfd7ed9db8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DalMobileNav_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DalAccountNav_seller" | "NavBarButtons_seller" | "NavItem_seller">;
  readonly " $fragmentType": "DalMobileNav_seller";
};
export type DalMobileNav_seller$key = {
  readonly " $data"?: DalMobileNav_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"DalMobileNav_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DalMobileNav_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavItem_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavBarButtons_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DalAccountNav_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "330cd8198cd372c7edb9047cb829fe29";

export default node;
