import { FC, ReactNode } from 'react';

import List from 'dibs-icons/exports/legacy/List';
import Envelope from 'dibs-icons/exports/legacy/Envelope';
import InfoCircle from 'dibs-icons/exports/legacy/InfoCircle';
import Box from 'dibs-icons/exports/legacy/Box';
import BarGraph from 'dibs-icons/exports/legacy/BarGraph';
import Home from 'dibs-icons/exports/legacy/Home';
import Megaphone from 'dibs-icons/exports/legacy/Megaphone';
import LightBulb from 'dibs-icons/exports/legacy/Lightbulb';

import styles from './styles/Icon.scss';

export const ICON_TYPES = [
    'performanceIcon',
    'advertisingIcon',
    'ordersIcon',
    'listingsIcon',
    'messageIcon',
    'supportIcon',
    'homeIcon',
    'recommendationsIcon',
];

const icons = {
    performanceIcon: BarGraph,
    // TODO: SEARCH-2261 advertisingIcon is not final
    advertisingIcon: Megaphone,
    ordersIcon: Box,
    listingsIcon: List,
    messageIcon: Envelope,
    supportIcon: InfoCircle,
    homeIcon: Home,
    recommendationsIcon: LightBulb,
};

type Props = {
    type: string;
    badge: ReactNode;
};

export const NavIcon: FC<Props> = ({ type, badge }) => {
    const Icon = icons[type as keyof typeof icons];

    return Icon ? (
        <span className={styles.icon}>
            <Icon className={styles.svgIcon} />
            {badge}
        </span>
    ) : null;
};
