import { useEffect, useState, FC, ReactNode } from 'react';
import { createRefetchContainer, graphql, RelayRefetchProp } from 'react-relay/legacy';
import BrowserNotificationsSignUpModal from 'dibs-browser-notifications/exports/BrowserNotificationsSignUpModal';
import { showBrowserNotificationsModal } from 'dibs-browser-notifications/exports/helpers';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import Nav from './Nav';
import { DealerWrapper_viewer$data } from './__generated__/DealerWrapper_viewer.graphql';

type Props = {
    viewer: DealerWrapper_viewer$data | null | undefined;
    isMobile: boolean;
    openNavBar: boolean;
    refetchIndex: number;
    customHeader: ReactNode;
    customBannerText?: string;
    openUserMenu: boolean;
    relay: RelayRefetchProp;
};

export const DealerNav: FC<Props> = ({ viewer, relay, isMobile, ...props }) => {
    const hasViewer = !!viewer;
    useEffect(() => {
        if (hasViewer) {
            relay.refetch(fragmentVariables => {
                return {
                    ...fragmentVariables,
                    customBannerText: props.customBannerText || '',
                    renderNotifications: fragmentVariables.hasSellerPk,
                };
            });
        }
    }, [hasViewer, relay, props.customBannerText, props.refetchIndex]);
    const navBar = viewer?.navBar;
    const links = navBar?.links || null;
    const buttons = navBar?.buttons || [];
    const notifications = navBar?.notifications || null;
    const seller = viewer?.seller || null;

    const showNotificationsModal =
        !isMobile &&
        Boolean(seller) && // we need to at least check that seller is logged in here
        window.Notification?.permission === 'default' &&
        showBrowserNotificationsModal();

    const [showModal, setShowModal] = useState(showNotificationsModal);

    useEffect(() => {
        setShowModal(showNotificationsModal);
    }, [showNotificationsModal]);

    return (
        <>
            <BrowserNotificationsSignUpModal
                showModal={showModal}
                onClose={() => setShowModal(false)}
            />
            <Nav
                seller={seller}
                buttons={buttons?.filter(filterNulls)}
                internalLinks={null}
                links={links}
                notifications={notifications}
                isMobile={isMobile}
                openUserMenu={props.openUserMenu}
                openNavBar={props.openNavBar}
                customHeader={props.customHeader}
            />
        </>
    );
};

export default createRefetchContainer(
    DealerNav,
    {
        viewer: graphql`
            fragment DealerWrapper_viewer on Viewer {
                seller(sellerId: $sellerPk) @include(if: $hasSellerPk) {
                    ...Nav_seller
                    ...useIsSellerOnline_seller
                }
                navBar: dealerNavBar(
                    sellerPk: $sellerPk
                    dotComHost: $dotComHost
                    hyperwalletLink: $hyperwalletLink
                    customBannerText: $customBannerText
                    userId: $userId
                ) {
                    notifications: badges @include(if: $renderNotifications) {
                        ...Nav_notifications
                    }

                    buttons {
                        ...Nav_buttons
                    }
                    links {
                        ...Nav_links
                    }
                }
            }
        `,
    },
    graphql`
        query DealerWrapperRefetchQuery(
            $sellerPk: String = ""
            $userId: String
            $dotComHost: String!
            $hyperwalletLink: String!
            $customBannerText: String = ""
            $renderNotifications: Boolean!
            $hasSellerPk: Boolean!
        ) {
            viewer {
                ...DealerWrapper_viewer
            }
        }
    `
);
