import { useContext } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { UserContext } from '../UserContext';
import { CustomNavItem } from './NavItems/CustomNavItem';

export function useBrandLink(): string {
    const { loginInternal, loginDealer, user, masqueradeSeller } = useContext(UserContext);
    if (masqueradeSeller) {
        return '/dealers';
    } else if (loginInternal) {
        return '/internal';
    } else if (loginDealer) {
        return '/dealers';
    } else if (user) {
        return '/internal';
    } else {
        return '/dealers';
    }
}

export function useUserMenuOptions(hasAccountNav: boolean): CustomNavItem[][] {
    // TODO: use UserContext instead of props
    const { user, masqueradeSeller } = useContext(UserContext);

    const isInternalUser = !!user;
    const isMasquerading = !!masqueradeSeller;
    let nameOrEmailTitle;
    if (user && user.profile) {
        nameOrEmailTitle = user.profile.firstName
            ? user.profile.firstName + ' ' + user.profile.lastName
            : user.profile.email;
    }
    const logoutLink = `/logout/${isInternalUser ? 'internal' : 'dealer'}`;

    const brandLink = useBrandLink();

    const div: CustomNavItem = { type: 'divider' };
    const logout: CustomNavItem = {
        href: logoutLink,
        dataTn: 'logout-link',
        title: <FormattedMessage id="dal.header.userMenuOptions.logout" defaultMessage="Logout" />,
        eventName: 'log out',
    };
    const dashboard: CustomNavItem = {
        href: brandLink,
        exactLocation: true,
        title: isMasquerading ? (
            <FormattedMessage
                id="dal.header.userMenuOptions.masquerading.home"
                defaultMessage="Administrator Dashboard"
            />
        ) : (
            <FormattedMessage id="dal.header.userMenuOptions.home" defaultMessage="Dashboard" />
        ),
    };
    const nameOrEmail: CustomNavItem = {
        title: nameOrEmailTitle,
    };
    const items1: CustomNavItem[] = [];
    if (isMasquerading || isInternalUser) {
        items1.push(dashboard);
    }
    if (isInternalUser) {
        items1.push(nameOrEmail);
    }
    if (isMasquerading && hasAccountNav) {
        items1.push(div);
    }

    const items2: CustomNavItem[] = [div, logout];

    return [items1, items2];
}
