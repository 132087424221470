'use strict';
require('dibs-sassy/exports/base.scss');
require('react');
require('react-dom');
require('react-dom/client');
require('relay-runtime');
require('react-relay/legacy');
require('react-relay');
require('@formatjs/intl-listformat/polyfill');
require('@formatjs/intl-listformat/locale-data/en');
require('@formatjs/intl-listformat/locale-data/it');
require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/en');
require('@formatjs/intl-pluralrules/locale-data/it');
require('wicg-inert');
require('focus-visible');

const smoothscroll = require('smoothscroll-polyfill');
smoothscroll.polyfill();
