import { FC, useState, useCallback, ReactNode } from 'react';
import classnames from 'classnames';

import { ExpandingArea } from '../ExpandingArea';

import styles from './main.scss';
type ClientRectHookReturnType = [ClientRect | null, (node: HTMLDivElement) => void];

function useClientRect(): ClientRectHookReturnType {
    const [rect, setRect] = useState<ClientRect | null>(null);
    const ref = useCallback((node: HTMLDivElement) => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
        }
    }, []);
    return [rect, ref];
}

type DropdownProps = {
    children: ReactNode;
    dataTn: string;
    isOpen: boolean;
    matchContainerWidth?: boolean;
    maxHeight?: number | string;
    hideTopBorder?: boolean;
    reverseDirection?: boolean;
    showOverflow?: boolean;
    alignRight?: boolean;
    hasShadow?: boolean;
    spillOutOfWindow?: boolean;
    detachedMode?: boolean;
    unmountCollapsedContent?: boolean;
    borderless?: boolean;
};

export const Dropdown: FC<DropdownProps> = props => {
    const {
        children,
        dataTn,
        isOpen,
        matchContainerWidth,
        maxHeight,
        hideTopBorder,
        reverseDirection,
        showOverflow,
        alignRight,
        hasShadow,
        spillOutOfWindow,
        detachedMode,
        unmountCollapsedContent,
        borderless,
    } = props;

    const [dropdownRect, dropdownRef] = useClientRect();
    const isOutsideViewportRightEdge =
        (dropdownRect && dropdownRect.right > window.innerWidth) || alignRight;

    const dropdownClasses = classnames(styles.dropdown, {
        [styles.dropdownExpanded]: isOpen,
        [styles.reverseDirection]: reverseDirection,
        [styles.hideDropdownBorderTop]: hideTopBorder && !reverseDirection,
        [styles.hideDropdownBorderBottom]: hideTopBorder && reverseDirection,
        [styles.alignRight]: isOutsideViewportRightEdge,
        [styles.dropdownShadow]: hasShadow,
        [styles.spillOutOfWindow]: spillOutOfWindow,
        [styles.detachedMode]: detachedMode,
        [styles.matchContainerWidth]: matchContainerWidth,
        [styles.borderless]: borderless,
    });

    let newMaxHeight = maxHeight;
    if (typeof maxHeight === 'string') {
        newMaxHeight = parseInt(maxHeight);
    }

    return (
        <div className={styles.wrapper}>
            <div data-tn={dataTn} ref={dropdownRef} className={dropdownClasses}>
                <ExpandingArea
                    expanded={isOpen}
                    maxHeight={newMaxHeight as number}
                    showOverflow={showOverflow}
                    unmountCollapsedContent={unmountCollapsedContent}
                >
                    {children}
                </ExpandingArea>
            </div>
        </div>
    );
};
