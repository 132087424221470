import SV from 'server-vars';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { createFetcher } from 'dibs-relay-network-layer/client';
import { setupSubscriptions } from 'dibs-relay-network-layer/subscriptionsClient';
import {
    getSellerToken,
    getInternalToken,
    BuyerTokenObject,
    SellerTokenObject,
} from 'dibs-cookie-jar';

export function getRelayEnvironment(userType: 'internal' | 'seller'): Environment {
    const source = new RecordSource();
    const store = new Store(source);
    function getQueries(): BuyerTokenObject | SellerTokenObject {
        const params = { locale: SV.get('locale') };
        if (userType === 'seller') {
            return { ...getSellerToken(document.cookie), ...params };
        } else if (userType === 'internal') {
            return { ...getInternalToken(document.cookie), ...params };
        }
        throw new Error('createNewEnvironment requires a userType');
    }
    const { fetchOrSubscribe } = setupSubscriptions({ getQueries, userType });
    const network = Network.create(createFetcher({ userType, getQueries }), fetchOrSubscribe);

    return new Environment({ network, store });
}
