/**
 * @generated SignedSource<<5ababb949caac15113a885f5aae740d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DalMobileNav_internalLinks$data = {
  readonly mobileNav: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"NavItem_navItem">;
  } | null> | null;
  readonly " $fragmentType": "DalMobileNav_internalLinks";
};
export type DalMobileNav_internalLinks$key = {
  readonly " $data"?: DalMobileNav_internalLinks$data;
  readonly " $fragmentSpreads": FragmentRefs<"DalMobileNav_internalLinks">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DalMobileNav_internalLinks",
  "selections": [
    {
      "alias": "mobileNav",
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "desktopNav",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NavItem_navItem"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavBarLinks",
  "abstractKey": null
};

(node as any).hash = "d72d0ff981a2e4a6cabe52230d95e723";

export default node;
