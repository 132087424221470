import { useRef, FC, RefObject, ReactNode } from 'react';

import * as React from 'react';
import classnames from 'classnames';
import styles from './styles/Tooltip.scss';
import useRefPropertyFactory from '../hooks/useRefPropertyFactory';

const TOOLTIP_ARROW_WIDTH = 14;
const MIN_MARGIN = 9;

// a better implementation might used fixed positions and overlay the tooltips
const rerenderOnResize = useRefPropertyFactory({
    properties: 'getBoundingClientRect',
    event: 'resize',
});

const calcTooltipOffset = (tooltipRef: RefObject<HTMLElement>): number => {
    if (!tooltipRef?.current) {
        return 0;
    }
    const { right = 0 } = tooltipRef.current.getBoundingClientRect();
    const offset = parseInt(window.getComputedStyle(tooltipRef.current).getPropertyValue('left'));
    const overflowAmount = right + MIN_MARGIN - window.innerWidth;
    if (overflowAmount < 0) {
        return 0;
    } else if (offset < 0) {
        return offset - overflowAmount;
    } else if (overflowAmount > 0) {
        return -overflowAmount;
    }
    return 0;
};

type Props = {
    dataTn?: string;
    open?: boolean;
    offsetWidth?: number;
    className?: string;
    container?: 'div' | 'ul';
    children: ReactNode;
};

export const Tooltip: FC<Props> = ({
    dataTn,
    open = false,
    offsetWidth,
    container = 'div',
    children,
    className,
}) => {
    const Container = container;
    const tooltipRef = useRef<HTMLElement>(null);
    rerenderOnResize(tooltipRef);
    const offset = tooltipRef.current ? calcTooltipOffset(tooltipRef) : 0;
    // tooltip arrow should point to the center of the parent
    // which would be half the parent's width plus half the arrow width
    // or default to one arrow's width
    const tooltipArrowOffset =
        (offsetWidth && offsetWidth / 2 - TOOLTIP_ARROW_WIDTH / 2 - offset) || TOOLTIP_ARROW_WIDTH;
    return (
        <Container
            // `Container` will be a `div` or an `ul` element at this point so keep `data-tn`
            // eslint-disable-next-line ferrum/invalid-prop-format
            data-tn={dataTn}
            ref={tooltipRef as $TSFixMe}
            className={classnames(styles.tooltip, { [styles.open]: open }, className)}
            style={
                {
                    '--tooltipArrowOffset': tooltipArrowOffset + 'px',
                    '--tooltipOffsetLeft': offset + 'px',
                } as React.CSSProperties
            }
        >
            {children}
        </Container>
    );
};
