import { useEffect, useCallback, useContext } from 'react';
import { graphql, useRelayEnvironment, useFragment } from 'react-relay';
import { defineMessages, useIntl } from 'dibs-react-intl';

import { trackBrowserNotification } from 'dibs-browser-notifications/exports/tracking';

import { UserContext } from '../UserContext';
import useTabNotifications from './useTabNotifications';
import newMilestoneSellerSubscription from './newMilestoneSellerSubscription';
import { useSellerNotifications_seller$key } from './__generated__/useSellerNotifications_seller.graphql';
import {
    newMilestoneSellerSubscription$data,
    MilestoneType,
} from './__generated__/newMilestoneSellerSubscription.graphql';

export type MilestoneNotificationType = 'MESSAGE' | 'ORDER' | 'OFFER';

const TRACKING_MAP = {
    MESSAGE: 'Message',
    ORDER: 'Offer',
    OFFER: 'Order',
} as const;

const MESSAGE_MILESTONE_TYPE = 'CONVERSATION_MESSAGE';
const TRANSACTION_MILESTONE_TYPES: MilestoneType[] = [
    'CHECKOUT_FULL_PRICE_SENT_TO_DEALER',
    'DEALER_OFFER_ACCEPTED_BY_BUYER_POST_CHECKOUT',
];
const OFFER_MILESTONE_TYPES: MilestoneType[] = [
    'BUYER_COUNTER_OFFER',
    'CHECKOUT_BUYER_INITIATED_NEGOTIATION',
    'CHECKOUT_NEGOTIATION_SENT_TO_DEALER',
];
const MILESTONE_TYPES_TO_FILTER: MilestoneType[] = [
    ...TRANSACTION_MILESTONE_TYPES,
    ...OFFER_MILESTONE_TYPES,
    MESSAGE_MILESTONE_TYPE,
];

const sellerFragment = graphql`
    fragment useSellerNotifications_seller on Seller {
        serviceId
    }
`;

const messages = defineMessages({
    notification: {
        id: 'dal.useSellerNotifications.notification',
        defaultMessage:
            'New {notificationType, select, ORDER {Order} OFFER {Offer} MESSAGE {Message} other {Notification}}{hasName, select, true { from {name}} other {}}',
    },
});

const useSellerNotifications = (
    sellerRef: useSellerNotifications_seller$key | null | undefined
): null => {
    const intl = useIntl();
    const environment = useRelayEnvironment();
    const seller = useFragment(sellerFragment, sellerRef);
    const { isDealer } = useContext(UserContext);
    const { serviceId: sellerId } = seller || {};

    const updateTabNotifications = useTabNotifications();

    const onNewMilestone = useCallback(
        (response?: newMilestoneSellerSubscription$data | null | undefined) => {
            const { milestone, milestoneNotification } = response?.newMilestoneSeller || {};
            const { serviceId, type, buyerName } = milestoneNotification || {};
            const { textBody, from, transaction } = milestone || {};
            const isNewMessage = type === MESSAGE_MILESTONE_TYPE;

            // Sellers are probably not interested in their own messages
            if (!type || (isNewMessage && from?.userType === 'SELLER')) {
                return;
            }

            let notificationType: MilestoneNotificationType | undefined;
            if (isNewMessage) {
                notificationType = 'MESSAGE';
            } else if (TRANSACTION_MILESTONE_TYPES.includes(type)) {
                notificationType = 'ORDER';
            } else if (OFFER_MILESTONE_TYPES.includes(type)) {
                notificationType = 'OFFER';
            }

            if (notificationType) {
                updateTabNotifications(notificationType, buyerName);

                if (window.Notification?.permission === 'granted') {
                    const titleCopy = intl.formatMessage(messages.notification, {
                        notificationType,
                        hasName: !!buyerName,
                        name: buyerName,
                    });

                    const options: NotificationOptions = {
                        tag: `new-notification-${serviceId}`,
                        icon: 'https://a.1stdibscdn.com/dist/adhoc/logo/seller-logo.png',
                    };

                    if (notificationType === 'MESSAGE' && textBody) {
                        options.body = textBody;
                    } else if (transaction?.item?.title) {
                        options.body = transaction.item.title;
                    }

                    const notification = new Notification(titleCopy, options);

                    notification.onclick = event => {
                        event.preventDefault();
                        const transactionId = milestone?.transaction?.serviceId;
                        const path =
                            notificationType === 'MESSAGE'
                                ? `/dealers/conversations/${
                                      milestone?.conversationId || ''
                                  }?utm_source=browser_notification`
                                : `/dealers/order-manager/${
                                      transactionId
                                          ? `?query=${transactionId}&utm_source=browser_notification`
                                          : '?utm_source=browser_notification'
                                  }`;
                        window.open(path, '_blank');
                    };

                    trackBrowserNotification(TRACKING_MAP[notificationType]);
                }
            }
        },
        [intl, updateTabNotifications]
    );

    useEffect(() => {
        if (!sellerId || !isDealer) {
            return () => {};
        }

        const unsubscribe = newMilestoneSellerSubscription(
            environment,
            { sellerId, filter: MILESTONE_TYPES_TO_FILTER },
            onNewMilestone
        );

        return () => {
            unsubscribe?.dispose();
        };
    }, [sellerId, environment, isDealer, onNewMilestone]);

    return null;
};

export default useSellerNotifications;
