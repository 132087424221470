/**
 * @generated SignedSource<<6c5e108fd3bb05af010d9aaa346fd394>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavItem_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountManagerComponent_seller" | "NavDropdownItem_seller" | "VatReportModal_seller">;
  readonly " $fragmentType": "NavItem_seller";
};
export type NavItem_seller$key = {
  readonly " $data"?: NavItem_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavItem_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavItem_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavDropdownItem_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountManagerComponent_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VatReportModal_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "6224522319410527b64a11e07f87e4d3";

export default node;
