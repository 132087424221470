import { Fragment, useContext, FC, ReactNode } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { UserContext } from '../../UserContext';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

import { ExpandingList } from '../ExpandingList';
import NavBarButtons from '../NavItems/NavBarButtons';
import NavItem from '../NavItems/NavItem';
import DalAccountNav from './DalAccountNav';
import styles from './styles/Nav.scss';

import { DalMobileNav_seller$data } from './__generated__/DalMobileNav_seller.graphql';
import { DalMobileNav_buttons$data } from './__generated__/DalMobileNav_buttons.graphql';
import { DalMobileNav_internalLinks$data } from './__generated__/DalMobileNav_internalLinks.graphql';
import { DalMobileNav_links$data } from './__generated__/DalMobileNav_links.graphql';
import { DalMobileNav_notifications$data } from './__generated__/DalMobileNav_notifications.graphql';

type Props = {
    seller: DalMobileNav_seller$data | null | undefined;
    buttons: DalMobileNav_buttons$data | null | undefined;
    internalLinks: DalMobileNav_internalLinks$data | null | undefined;
    links: DalMobileNav_links$data | null | undefined;
    notifications: DalMobileNav_notifications$data | null | undefined;
    isMobile: boolean;
    openUserMenu: boolean;
    openNavBar: boolean;
    customHeader?: ReactNode;
};

export const DalMobileNav: FC<Props> = ({
    links,
    internalLinks,
    buttons,
    notifications,
    openNavBar,
    customHeader,
    openUserMenu,
    isMobile,
    seller,
}) => {
    const { isDealer } = useContext(UserContext);
    let mobileNav;
    let accountNav;
    if (isDealer) {
        ({ mobileNav, accountNav } = links || {});
    } else {
        ({ mobileNav } = internalLinks || {});
    }
    return (
        <Fragment>
            {!customHeader && (
                <ExpandingList
                    dataTn="navMenuExpandingList"
                    open={openNavBar}
                    className={styles.expandingMenu}
                >
                    {isDealer && !!seller && (
                        <NavBarButtons buttons={buttons} seller={seller} isMobile />
                    )}
                    {(mobileNav || []).map(
                        (navItem, i) =>
                            !!navItem && (
                                <NavItem
                                    key={`mobileNav-${i}`}
                                    isMobile={isMobile}
                                    notifications={notifications}
                                    navItem={navItem}
                                    seller={seller}
                                />
                            )
                    )}
                </ExpandingList>
            )}
            <ExpandingList
                dataTn="userMenuExpandingList"
                open={openUserMenu}
                className={styles.expandingMenu}
            >
                <DalAccountNav
                    isMobile
                    accountNav={(accountNav || []).filter(filterNulls)}
                    seller={seller}
                />
            </ExpandingList>
        </Fragment>
    );
};

export default createFragmentContainer(DalMobileNav, {
    seller: graphql`
        fragment DalMobileNav_seller on Seller {
            ...NavItem_seller
            ...NavBarButtons_seller
            ...DalAccountNav_seller
        }
    `,
    buttons: graphql`
        fragment DalMobileNav_buttons on NavBarButton @relay(plural: true) {
            ...NavBarButtons_buttons
        }
    `,
    internalLinks: graphql`
        fragment DalMobileNav_internalLinks on NavBarLinks {
            mobileNav: desktopNav {
                ...NavItem_navItem
            }
        }
    `,
    links: graphql`
        fragment DalMobileNav_links on NavBarLinks {
            mobileNav {
                ...NavItem_navItem
            }
            accountNav {
                ...DalAccountNav_accountNav
            }
        }
    `,
    notifications: graphql`
        fragment DalMobileNav_notifications on DealerNavBarBadges {
            ...NavItem_notifications
        }
    `,
});
