import { useState, useEffect, useCallback, useRef } from 'react';

export function usePageVisibility(): boolean {
    const [isVisible, setIsVisible] = useState(
        Boolean(document && document.visibilityState === 'visible')
    );

    const visibilityChange = useCallback(() => {
        setIsVisible(document.visibilityState === 'visible');
    }, []);

    useEffect(() => {
        document.addEventListener('visibilitychange', visibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', visibilityChange);
        };
    }, [visibilityChange]);

    return isVisible;
}

// similar to usePageVisibility, but page does not show as hidden until `timeout`
export function usePageVisibilityTimeout(timeout: number): boolean {
    const isVisible = usePageVisibility();
    const [isVisibleDelayed, setIsVisibleDelayed] = useState(isVisible);
    const timeoutRef = useRef<number | undefined>();

    useEffect(() => {
        if (isVisible) {
            clearTimeout(timeoutRef.current);
            setIsVisibleDelayed(true);
        } else {
            timeoutRef.current = window.setTimeout(() => {
                setIsVisibleDelayed(false);
            }, timeout);
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isVisible, timeout]);

    return isVisibleDelayed;
}
