import { FC, useState } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { ModalBody } from 'dibs-elements/exports/ModalBody';
import { Button } from 'dibs-elements/exports/Button';
import { Spinner } from 'dibs-elements/exports/Spinner';
import { vatMessages } from 'dibs-vat-utils/exports/vatMessages';
import DatePicker from 'dibs-date-picker/exports/DatePicker';
import SV from 'server-vars';
import styles from './styles/VatReportModal.scss';
import { VatReportModal_seller$key } from './__generated__/VatReportModal_seller.graphql';

export const VAT_REPORT_MODAL_HASH = '#vat-report-modal';

// turns javascript Date object to mm/dd/yy string
// offset is number of days to add
const formatDateForBE = (input: Date, offset?: number): string => {
    const date = new Date(input);

    if (offset) {
        // add offset as days
        date.setDate(date.getDate() + offset);
    }

    return date.toLocaleString('en-US', { dateStyle: 'short' });
};

type Props = {
    seller: VatReportModal_seller$key | null | undefined;
    isOpen: boolean;
    onClose: () => void;
};
export const VatReportModal: FC<Props> = ({ seller: sellerRef, isOpen, onClose }) => {
    const seller = useFragment(
        graphql`
            fragment VatReportModal_seller on Seller {
                vatConfiguration {
                    priceBookName
                }
            }
        `,
        sellerRef
    );
    const { priceBookName } = seller?.vatConfiguration || {};
    const today = new Date();
    const firstDayPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const [startDateInput, setStartDate] = useState<Date>(firstDayPrevMonth);
    const [endDateInput, setEndDate] = useState<Date>(lastDayPrevMonth);

    const [downloading, setDownloading] = useState(false);
    const [serverError, setServerError] = useState(false);

    const handleDownload = async (): Promise<void> => {
        setDownloading(true);

        // stringify dates for BE
        const startDate = formatDateForBE(startDateInput);
        const endDate = formatDateForBE(endDateInput, 1);

        // get generated csv file
        const { token } = SV.get('sellerData') || {};
        const csvReportUrl = `/soa/ecom-4/2/sellerVatOrdersReport/?sellerToken=${token}&startDate=${startDate}&endDate=${endDate}`;
        const csvReportResponse = await fetch(csvReportUrl, {
            credentials: 'omit',
        });
        if (csvReportResponse.status !== 200) {
            setServerError(true);
            setDownloading(false);
            return;
        }
        const csvContents = (await csvReportResponse.text()) || '';

        // generate a temp file for browser download
        const file = new File([csvContents], 'download.csv');
        const link = document.createElement('a');
        const url = URL.createObjectURL(file);
        link.href = url;
        link.download = file.name;

        // trigger download
        document.body.appendChild(link);
        link.click();

        // cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setDownloading(false);

        // close modal
        onClose();
    };

    if (!priceBookName) {
        return null;
    }

    return (
        <ModalContainer isOpen={isOpen} onClose={onClose} modalClass={styles.modal}>
            <ModalCloseButton onClick={onClose} />
            <BarHeader title={vatMessages.downloadReport({ priceBookName })} />
            <ModalBody>
                <p className={styles.description}>
                    <FormattedMessage
                        id="VatReportModal.description"
                        defaultMessage="Set the period and click the button to download the CSV report."
                    />
                </p>

                <div className={styles.datePickerWrapper}>
                    <DatePicker
                        dataTn="vatReportModal.startDate"
                        label={
                            <FormattedMessage
                                id="VatReportModal.startDate"
                                defaultMessage="Start Date*"
                            />
                        }
                        name="vatReportModal.startDate"
                        value={startDateInput.toISOString()}
                        onChange={({ value }) => setStartDate(value)}
                        lastAvailableDay={endDateInput}
                        showCalendarIcon
                    />
                </div>

                <div className={styles.datePickerWrapper}>
                    <DatePicker
                        dataTn="vatReportModal.endDate"
                        label={
                            <FormattedMessage
                                id="VatReportModal.endDate"
                                defaultMessage="End Date*"
                            />
                        }
                        name="vatReportModal.endDate"
                        value={endDateInput.toISOString()}
                        onChange={({ value }) => setEndDate(value)}
                        firstAvailableDay={startDateInput}
                        showCalendarIcon
                    />
                </div>

                <Button
                    disabled={downloading}
                    className={styles.downloadButton}
                    onClick={handleDownload}
                >
                    {downloading && <Spinner containerClass={styles.spinner} />}
                    <FormattedMessage id="VatReportModal.download" defaultMessage="DOWNLOAD" />
                </Button>

                {serverError && (
                    <p className={styles.serverError}>
                        <FormattedMessage
                            id="VatReportModal.serverError"
                            defaultMessage="Something went wrong, and we were not able to export the report. Please try again or come back later if it keeps happening."
                        />
                    </p>
                )}
            </ModalBody>
        </ModalContainer>
    );
};
