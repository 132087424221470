import { FC, Suspense, lazy } from 'react';

import { GetHelpModalProps } from '../src/types';

const GetHelpModalSeller = lazy(
    () => import(/* webpackChunkName: "GetHelpModalSeller" */ '../src/GetHelpSeller')
);

const GetHelpModalSellerLazy: FC<GetHelpModalProps> = props =>
    props.isOpen ? (
        <Suspense fallback={null}>
            <GetHelpModalSeller {...props} isOpen />
        </Suspense>
    ) : null;

export default GetHelpModalSellerLazy;
