import { useEffect } from 'react';

export default function useDetectOutsideClick(ref, cb) {
    useEffect(() => {
        const handler = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                cb();
            }
        };
        window.addEventListener('mouseup', handler);
        return () => {
            window.removeEventListener('mouseup', handler);
        };
    }, [ref, cb]);
}
