import {
    setPreviousOptInNotificationsData,
    getShouldShowOptIn,
} from 'dibs-presence/exports/helpers';

const BROWSER_NOTIFICATIONS_STORAGE_KEY = 'sellerBrowserNotifications';

export const showBrowserNotificationsModal = (): boolean => {
    return getShouldShowOptIn(BROWSER_NOTIFICATIONS_STORAGE_KEY);
};

export const setBrowserNotificationsData = (): void => {
    setPreviousOptInNotificationsData(BROWSER_NOTIFICATIONS_STORAGE_KEY);
};
