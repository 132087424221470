import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import styles from './styles/CalendarWeek.scss';

type Props = {
    children: ReactNode;
    isFirstMonthWeek: boolean;
};

export const CalendarWeek: FC<Props> = ({ children, isFirstMonthWeek }) => {
    return (
        <div
            className={classnames(styles.container, {
                [styles.isFirstMonthWeek]: isFirstMonthWeek,
            })}
        >
            {children}
        </div>
    );
};
