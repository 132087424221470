/* 
SEND_HEARTBEAT_INTERVAL - frequency of updates sent as current user is active
    set high to reduce the number of requests/events
USER_ONLINE_INTERVAL - time to show presence after a heartbeat event is received
    must be higher than SEND_HEARTBEAT_INTERVAL so that online presence shows continuously while we wait for the next event
*/
export const SEND_HEARTBEAT_INTERVAL = 180000; // 3 minutes
export const USER_ONLINE_INTERVAL = 190000; // 3 minutes 10 seconds
export const SEND_HEARTBEATS_IN_BACKGROUND = 3600000; // 1 hour
export const LISTENERS = ['mousemove', 'scroll', 'keydown', 'resize'];
