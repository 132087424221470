import { useEffect, useRef, useState, ComponentType } from 'react';
import * as React from 'react';
import { Input } from 'dibs-elements/exports/Input';
import Calendar from 'dibs-icons/exports/legacy/Calendar';
import { CalendarMonth } from './CalendarMonth';
import { Dropdown } from 'dibs-elements/exports/Dropdown';

import { formatDate } from './helpers/datesHelper';

import styles from './styles/DatePicker.scss';

type Props = {
    onChange: (selection: {
        formattedDate: string;
        value: Date;
        name: string | undefined;
        isoDate: string;
    }) => void;
    dataTn: string;
    autoFocus?: boolean;
    disabled?: boolean;
    disablePastDates?: boolean;
    disableFutureDates?: boolean;
    disablePreviousMonths?: boolean;
    errorMessage?: string;
    firstAvailableDay?: Date;
    hasError?: boolean;
    horizontalSpacing?: 'default' | 'small';
    initialVisibleMonth?: Date;
    label?: React.ReactNode;
    lastAvailableDay?: Date;
    name?: string;
    onBlur?: () => void;
    placeholder?: string;
    dateFormatOptions?: Intl.DateTimeFormatOptions;
    size?: string;
    value?: string;
    alignRight?: boolean;
    showCalendarIcon?: boolean;
    showCalendarIconNumber?: boolean;
    spillOutOfWindow?: boolean;
};

const DatePickerComponent: ComponentType<Props> = ({
    autoFocus = false,
    dataTn,
    disabled = false,
    label,
    placeholder = 'MMM D, YYYY',
    dateFormatOptions,
    size,
    value,
    onChange,
    firstAvailableDay,
    lastAvailableDay,
    disablePastDates = false,
    disableFutureDates = false,
    initialVisibleMonth,
    disablePreviousMonths = false,
    errorMessage,
    hasError,
    name,
    horizontalSpacing = 'default',
    onBlur,
    alignRight = false,
    showCalendarIcon,
    showCalendarIconNumber = true,
    spillOutOfWindow = false,
}: Props) => {
    const inputRef = useRef<HTMLDivElement | null>(null);
    const [isShowingPicker, setIsShowingPicker] = useState<boolean>(autoFocus);

    function handleDayClick(day: Date): void {
        if (!day) {
            return;
        }
        /**
         * Keep this backwards compatible with the regular input[type="date"] value. It returns
         * date in the ISO YYYY-MM-DD format.
         *
         * Months are zero-based values!
         */
        const isoDate = [day.getFullYear(), day.getMonth() + 1, day.getDate()]
            .map(num => {
                if (num < 10) {
                    return `0${num}`;
                }
                return num;
            })
            .join('-');
        const val = formatDate(day);
        onChange({ formattedDate: val, value: day, name, isoDate });
        setIsShowingPicker(false);
    }

    function toggleDropdown(): void {
        setIsShowingPicker(!isShowingPicker);
    }

    useEffect(() => {
        const handleOutsideClick = (e: Event): void => {
            if (
                inputRef &&
                inputRef.current &&
                !inputRef.current.contains(e.target as HTMLElement)
            ) {
                setIsShowingPicker(false);
            }
        };
        document.addEventListener('click', handleOutsideClick, true);
        return () => document.removeEventListener('click', handleOutsideClick, true);
    }, [setIsShowingPicker]);

    return (
        <div ref={inputRef}>
            <div onClick={toggleDropdown} onKeyPress={toggleDropdown} role="textbox" tabIndex={-1}>
                <Input
                    autoFocus={autoFocus}
                    dataTn={dataTn}
                    disabled={disabled}
                    label={label}
                    name={name}
                    placeholder={placeholder}
                    showIcons={false}
                    size={size}
                    value={value ? formatDate(new Date(value), dateFormatOptions) : ''}
                    readOnly
                    hasError={hasError}
                    errorMessage={!isShowingPicker && errorMessage}
                    horizontalSpacing={horizontalSpacing}
                    onBlur={onBlur}
                    rightDecorator={
                        showCalendarIcon ? (
                            <Calendar
                                showNumber={showCalendarIconNumber}
                                className={styles.calendarIcon}
                            />
                        ) : null
                    }
                />
            </div>
            <div className={styles.dropdownWrapper}>
                <Dropdown
                    dataTn={`${dataTn}-dropdown`}
                    isOpen={!disabled && (isShowingPicker || false)}
                    alignRight={alignRight}
                    spillOutOfWindow={spillOutOfWindow}
                >
                    <div className={styles.datepicker}>
                        <CalendarMonth
                            firstAvailableDate={firstAvailableDay}
                            disablePastDates={disablePastDates}
                            disableFutureDates={disableFutureDates}
                            initialVisibleMonth={initialVisibleMonth}
                            onDayClick={handleDayClick}
                            disablePreviousMonths={disablePreviousMonths}
                            lastAvailableDate={lastAvailableDay}
                            value={value}
                        />
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};

export const DatePicker = DatePickerComponent;
