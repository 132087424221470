/**
 * @generated SignedSource<<5588f413a83adbc0ef6d775852ffcebc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountManagerComponent_seller$data = {
  readonly sellerRep: {
    readonly email: string | null;
  } | null;
  readonly " $fragmentType": "AccountManagerComponent_seller";
};
export type AccountManagerComponent_seller$key = {
  readonly " $data"?: AccountManagerComponent_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountManagerComponent_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountManagerComponent_seller",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RepData",
      "kind": "LinkedField",
      "name": "sellerRep",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "18f20e3f473f2a68a37bd2f09554676f";

export default node;
