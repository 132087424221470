/**
 * @generated SignedSource<<5879df32bd8829ee7ee42cc5a447b700>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Nav_buttons$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"DalMobileNav_buttons" | "DesktopNav_buttons">;
  readonly " $fragmentType": "Nav_buttons";
}>;
export type Nav_buttons$key = ReadonlyArray<{
  readonly " $data"?: Nav_buttons$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav_buttons">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Nav_buttons",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DalMobileNav_buttons"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopNav_buttons"
    }
  ],
  "type": "NavBarButton",
  "abstractKey": null
};

(node as any).hash = "00879c701bba5aa666edf93988ec7a06";

export default node;
