/**
 * @generated SignedSource<<f624750910d65d03e181963c4cf50503>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DalAccountNav_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NavDropdownItem_seller" | "NavItem_seller">;
  readonly " $fragmentType": "DalAccountNav_seller";
};
export type DalAccountNav_seller$key = {
  readonly " $data"?: DalAccountNav_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"DalAccountNav_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DalAccountNav_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavItem_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavDropdownItem_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "31d03efe89fd0934ea64aa1831bfcf1e";

export default node;
