import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { formatDate } from './helpers/datesHelper';
import styles from './styles/CalendarDay.scss';

type Props = {
    day?: Date;
    disabled: boolean | null;
    selected: boolean | null;
    onDayClick: (day: Date) => void;
    isToday: boolean | null;
    isOutside: boolean | null;
};

export const CalendarDay: FunctionComponent<Props> = ({
    day,
    disabled,
    selected,
    onDayClick,
    isToday,
    isOutside,
}) => {
    if (!day) {
        return null;
    }

    const classes = classnames(styles.day, {
        [styles.selected]: selected,
        [styles.today]: isToday,
        [styles.outside]: isOutside,
    });

    const dayNumber = formatDate(day, { day: 'numeric' });

    const dataTn = `day-${dayNumber}-${disabled || isOutside ? 'disabled' : 'enabled'}`;

    return (
        <div
            className={classnames(styles.wrapper, { [styles.disabled]: disabled })}
            onClick={() => {
                if (disabled) return;
                onDayClick(day);
            }}
        >
            <div className={classes} data-tn={dataTn}>
                {dayNumber}
            </div>
        </div>
    );
};
