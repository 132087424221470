/**
 * @generated SignedSource<<9e897cafa617376eb4ebd5c906a53e1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopNav_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DalAccountNav_seller" | "NavBarButtons_seller" | "NavItem_seller" | "useSellerNotifications_seller">;
  readonly " $fragmentType": "DesktopNav_seller";
};
export type DesktopNav_seller$key = {
  readonly " $data"?: DesktopNav_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopNav_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopNav_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavItem_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavBarButtons_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DalAccountNav_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSellerNotifications_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "405ca721de044ba793d5a1f1a516b86e";

export default node;
