/**
 * @generated SignedSource<<904798b292bf9487de18752f8b4c676f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopNav_internalLinks$data = {
  readonly desktopNav: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"NavItem_navItem">;
  } | null> | null;
  readonly " $fragmentType": "DesktopNav_internalLinks";
};
export type DesktopNav_internalLinks$key = {
  readonly " $data"?: DesktopNav_internalLinks$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopNav_internalLinks">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopNav_internalLinks",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "desktopNav",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NavItem_navItem"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavBarLinks",
  "abstractKey": null
};

(node as any).hash = "beaeff37b48b3995852b8016d1314254";

export default node;
