import { localStorage } from 'dibs-browser-storage';

// number of days passed before showing notification again
const NOTIFICATION_DELAY_DAYS = [0, 1, 7, 14];

type PreviousOptInNotificationsData = null | {
    timesShown: number;
    lastShownTime: number;
};

// 86400000 = 24 * 60 * 60 * 1000 = hours in day * minutes in hour * seconds in minute * milliseconds in second
const millisecondsToDays = (milliseconds: number): number => Math.floor(milliseconds / 86400000);

const getPreviousOptInNotificationsData = (storageKey: string): PreviousOptInNotificationsData => {
    const data = localStorage.getItem(storageKey);
    if (!data) {
        return null;
    } else {
        return data as PreviousOptInNotificationsData;
    }
};

export const setPreviousOptInNotificationsData = (storageKey: string): void => {
    const { timesShown } = getPreviousOptInNotificationsData(storageKey) || { timesShown: 0 };

    const newData = {
        timesShown: timesShown + 1,
        lastShownTime: new Date().getTime(),
    };

    localStorage.setItem(storageKey, newData);
};

export const getShouldShowOptIn = (
    storageKey: string,
    isGDPRApplicable: boolean = true,
    isActive: boolean = false,
    isInternal: boolean = false
): boolean => {
    const previousOptInNotifications = getPreviousOptInNotificationsData(storageKey);

    if (!previousOptInNotifications) {
        return true && (isActive || isInternal);
    }
    if (!isGDPRApplicable) {
        return false;
    }
    const { timesShown, lastShownTime } = previousOptInNotifications;

    // length - 1 becuase 0 is a placeholder
    if (timesShown >= NOTIFICATION_DELAY_DAYS.length - 1) {
        return false;
    } else {
        const timeSinceLastShown = new Date().getTime() - lastShownTime;
        const daysSinceLastShown = millisecondsToDays(timeSinceLastShown);

        return (
            daysSinceLastShown >= NOTIFICATION_DELAY_DAYS[timesShown] && (isActive || isInternal)
        );
    }
};
