import { useState, useEffect, useRef } from 'react';

/**
 * a function that returns a hook function
 * @param {Object} param0
 * @param {string|string[]} param0.properties the property or properties to get watch in the hook
 * @param {Object} [param0.event] name of the event to listen for
 * @param {number} [param0.eventTimeout=100] the timeout for throttling the listener
 * @return {Function} the generated hook
 */
export default function ({ properties, event, eventTimeout = 100 }) {
    return function (ref) {
        const resizeTimeout = useRef();
        const [propertyValue, setPropertyValue] = useState(Array.isArray(properties) ? [] : null);

        useEffect(() => {
            function handleSetProperty() {
                const getValue = property =>
                    typeof ref.current[property] === 'function'
                        ? ref.current[property]()
                        : ref.current[property];
                if (ref.current) {
                    if (Array.isArray(properties)) {
                        const propertiesValues = [];
                        properties.forEach(property => {
                            propertiesValues.push(getValue(property));
                        });
                        setPropertyValue(propertiesValues);
                    } else {
                        setPropertyValue(getValue(properties));
                    }
                }
            }
            function throttle() {
                if (!resizeTimeout.current) {
                    resizeTimeout.current = setTimeout(function () {
                        resizeTimeout.current = null;
                        handleSetProperty();
                    }, eventTimeout);
                }
            }
            if (event) {
                window.addEventListener(event, throttle);
            }
            // initial execution
            handleSetProperty();
            return () => {
                if (event) {
                    window.removeEventListener(event, throttle);
                }
            };
        }, [ref]);
        return propertyValue;
    };
}
