import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type PersonProfileProps = {
    className?: string;
};

const PersonProfile: FunctionComponent<PersonProfileProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'person-profile'}
            viewBox="0 0 250 250"
        >
            <path d="M138.7 243.1c-37.1 0-82.3-.1-133.4-.3H.5l.3-4.8c.2-3.2 2.1-30.9 6.8-41.2.7-1.7 5.7-13.3 18.3-17.8l39.5-15.9 16.7-7.1c.3-.1 8.1-3.3 10.2-12.4.2-1.6.1-3.5-.2-4.1-.9-1.9-.9-1.9-3.3-4.4l-.1-.1c-.7-.6-16.1-18.9-19.7-45.3-.2-1.4-2.2-13.7-.6-28.5 2.3-20.8 11-36.3 24.8-45 1.3-.8 30.3-17.7 63.5-.2 2.6 1.3 35.1 18.4 25 74.4-.3 2.3-3.5 25.2-19.6 44.7-.3.4-.8.9-1.3 1.4-.9 1-2 2.2-2.7 3.3-.1.5-.4 2 .2 3.9l.2.6c.1.3 1.8 7.7 11.9 12.2l21.5 9.6 31.8 12.6c.7.3 19.2 7.5 23.1 32v.4l2.3 31.9h-4.8c-.5-.1-40.3.1-105.6.1zm-128.5-9.2c120.6.6 208.4.2 229.4.1l-1.6-22.1c-3.2-19.2-17.3-24.8-17.4-24.8l-32.2-12.8-21.6-9.7c-13.3-5.9-16.4-16.3-16.9-18.4-1.6-5.2-.1-9.3.1-9.8l.2-.4c1.2-2.4 3-4.3 4.3-5.7.4-.4.7-.7.9-1 15.1-18.1 17.6-39.9 17.6-40.1v-.3c9.2-50.6-19.8-64.9-20.1-65l-.2-.1C124 8.9 99 23.2 98 23.8 69.9 41.2 77.8 87.7 77.9 88.1v.2c3.1 23.2 16.7 39.9 17.6 40.9 2.9 3.2 3.2 3.5 4.6 6.4 1.8 3.5 1.1 8.5 1 9.5l-.1.3c-3 13.5-14.2 18.5-15.7 19.1l-16.5 7.1-39.8 16c-9.5 3.4-13.1 12.6-13.1 12.7l-.1.3c-2.8 6.1-4.8 23.3-5.6 33.3z" />
        </svg>
    );
};
export default PersonProfile;
