import { FC, ReactNode } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import MobileNav from './DalMobileNav';
import DesktopNav from './DesktopNav';

import { Nav_seller$data } from './__generated__/Nav_seller.graphql';
import { Nav_buttons$data } from './__generated__/Nav_buttons.graphql';
import { Nav_internalLinks$data } from './__generated__/Nav_internalLinks.graphql';
import { Nav_links$data } from './__generated__/Nav_links.graphql';
import { Nav_notifications$data } from './__generated__/Nav_notifications.graphql';

type Props = {
    seller: Nav_seller$data | null | undefined;
    buttons: Nav_buttons$data | null | undefined;
    internalLinks: Nav_internalLinks$data | null | undefined;
    links: Nav_links$data | null | undefined;
    notifications: Nav_notifications$data | null | undefined;
    isMobile: boolean;
    openUserMenu: boolean;
    openNavBar: boolean;
    customHeader: ReactNode;
};

const Nav: FC<Props> = ({
    seller,
    buttons,
    internalLinks,
    links,
    notifications,
    isMobile,
    openUserMenu,
    openNavBar,
    customHeader,
}) => {
    if (isMobile) {
        return (
            <MobileNav
                seller={seller}
                buttons={buttons}
                internalLinks={internalLinks}
                links={links}
                notifications={notifications}
                isMobile={isMobile}
                openUserMenu={openUserMenu}
                openNavBar={openNavBar}
                customHeader={customHeader}
            />
        );
    } else {
        return (
            <DesktopNav
                seller={seller}
                buttons={buttons}
                internalLinks={internalLinks}
                links={links}
                notifications={notifications}
                customHeader={customHeader}
            />
        );
    }
};

export default createFragmentContainer(Nav, {
    seller: graphql`
        fragment Nav_seller on Seller {
            ...DalMobileNav_seller
            ...DesktopNav_seller
        }
    `,
    buttons: graphql`
        fragment Nav_buttons on NavBarButton @relay(plural: true) {
            ...DalMobileNav_buttons
            ...DesktopNav_buttons
        }
    `,
    internalLinks: graphql`
        fragment Nav_internalLinks on NavBarLinks {
            ...DalMobileNav_internalLinks
            ...DesktopNav_internalLinks
        }
    `,
    links: graphql`
        fragment Nav_links on NavBarLinks {
            ...DalMobileNav_links
            ...DesktopNav_links
        }
    `,
    notifications: graphql`
        fragment Nav_notifications on DealerNavBarBadges {
            ...DalMobileNav_notifications
            ...DesktopNav_notifications
        }
    `,
});
