/**
 * @generated SignedSource<<d2401655d21c0708e414940bf8aade16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIsSellerOnline_seller$data = {
  readonly presence: {
    readonly recentOnlineDate: string | null;
  } | null;
  readonly sellerPreferences: {
    readonly showOnlineStatus: boolean | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "useIsSellerOnline_seller";
};
export type useIsSellerOnline_seller$key = {
  readonly " $data"?: useIsSellerOnline_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsSellerOnline_seller">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useIsSellerOnline_seller"
};

(node as any).hash = "7ec40414605e863b4a36acae2f69bccd";

export default node;
