/**
 * @generated SignedSource<<5908587bfc471eeed44760f72ebca6b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InternalWrapper_viewer$data = {
  readonly internalNavBarLinks: {
    readonly links: {
      readonly " $fragmentSpreads": FragmentRefs<"Nav_internalLinks">;
    } | null;
  } | null;
  readonly " $fragmentType": "InternalWrapper_viewer";
};
export type InternalWrapper_viewer$key = {
  readonly " $data"?: InternalWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternalWrapper_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternalWrapper_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "reduceShortGroups",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "InternalNavBar",
      "kind": "LinkedField",
      "name": "internalNavBarLinks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NavBarLinks",
          "kind": "LinkedField",
          "name": "links",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Nav_internalLinks"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "bd5dd4112dd0dbbf1e9a87dcfb86083f";

export default node;
