import { NotificationCounts } from '../hooks/useTabNotifications';
import { MilestoneNotificationType } from '../hooks/useSellerNotifications';
import { defineMessages, IntlShape } from 'dibs-react-intl';

const messages = defineMessages({
    singleNotification: {
        id: 'dal.getNotificationsMessage.singleNotification',
        defaultMessage:
            'New {notificationType, select, ORDER {Order} OFFER {Offer} other {Message}}{hasName, select, true { from {name}} other {}}',
    },
    multiNotification: {
        id: 'dal.getNotificationsMessage.multiNotification',
        defaultMessage:
            '{totalCount} New {notificationType, select,  MESSAGES {Messages} OFFERS {Offers} ORDERS {Orders} other {Notifications}}',
    },
});

type SingleNotification = MilestoneNotificationType | '';
type MultiNotifications = 'MESSAGES' | 'ORDERS' | 'OFFERS' | 'NOTIFICATIONS';

const getNotificationMessage = ({
    intl,
    notificationCounts,
    name,
}: {
    intl: IntlShape;
    notificationCounts: NotificationCounts;
    name?: string | null;
}): string | null => {
    const { MESSAGE = 0, OFFER = 0, ORDER = 0 } = notificationCounts;
    const totalCount = MESSAGE + OFFER + ORDER;

    if (!totalCount) {
        return null;
    }

    if (totalCount === 1) {
        let singleNotificationType: SingleNotification = '';
        if (MESSAGE === 1) {
            singleNotificationType = 'MESSAGE';
        } else if (ORDER === 1) {
            singleNotificationType = 'ORDER';
        } else if (OFFER === 1) {
            singleNotificationType = 'OFFER';
        }

        return intl.formatMessage(messages.singleNotification, {
            notificationType: singleNotificationType,
            hasName: !!name,
            name,
        });
    }

    const filteredCounts = Object.fromEntries(
        Object.entries(notificationCounts).filter(([, val]) => val)
    );

    let multiNotificationType: MultiNotifications = 'NOTIFICATIONS';
    if (Object.keys(filteredCounts).length === 1) {
        if (filteredCounts.MESSAGE) {
            multiNotificationType = 'MESSAGES';
        } else if (filteredCounts.OFFER) {
            multiNotificationType = 'OFFERS';
        } else if (filteredCounts.ORDER) {
            multiNotificationType = 'ORDERS';
        }
    }

    return intl.formatMessage(messages.multiNotification, {
        notificationType: multiNotificationType,
        totalCount,
    });
};

export default getNotificationMessage;
