/**
 * @generated SignedSource<<f10ee7aeaeadc9185d03aed1f121e2f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavHeader_viewer$data = {
  readonly dealerNavBar?: {
    readonly bannerText: string | null;
  } | null;
  readonly presenceFeatureFlag: boolean | null;
  readonly seller?: {
    readonly sellerPreferences: {
      readonly showOnlineStatus: boolean | null;
    } | null;
    readonly serviceId: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"useIsSellerOnline_seller" | "useSendPresenceHeartbeats_seller">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DealerWrapper_viewer" | "InternalWrapper_viewer" | "useIsSellerOnline_viewer" | "useSendPresenceHeartbeats_viewer">;
  readonly " $fragmentType": "NavHeader_viewer";
};
export type NavHeader_viewer$key = {
  readonly " $data"?: NavHeader_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavHeader_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": "presenceFeatureFlag",
  "args": [
    {
      "kind": "Literal",
      "name": "feature",
      "value": "presence"
    }
  ],
  "kind": "ScalarField",
  "name": "featureFlag",
  "storageKey": "featureFlag(feature:\"presence\")"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SellerPreferences",
  "kind": "LinkedField",
  "name": "sellerPreferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showOnlineStatus",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "customBannerText"
    },
    {
      "kind": "RootArgument",
      "name": "dotComHost"
    },
    {
      "kind": "RootArgument",
      "name": "hasSellerPk"
    },
    {
      "kind": "RootArgument",
      "name": "hyperwalletLink"
    },
    {
      "kind": "RootArgument",
      "name": "isDealer"
    },
    {
      "kind": "RootArgument",
      "name": "sellerPk"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavHeader_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "condition": "isDealer",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DealerWrapper_viewer"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "customBannerText",
              "variableName": "customBannerText"
            },
            {
              "kind": "Variable",
              "name": "dotComHost",
              "variableName": "dotComHost"
            },
            {
              "kind": "Variable",
              "name": "hyperwalletLink",
              "variableName": "hyperwalletLink"
            },
            {
              "kind": "Variable",
              "name": "sellerPk",
              "variableName": "sellerPk"
            },
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "DealerNavBar",
          "kind": "LinkedField",
          "name": "dealerNavBar",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bannerText",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "hasSellerPk",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "sellerId",
              "variableName": "sellerPk"
            }
          ],
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useSendPresenceHeartbeats_seller",
              "selections": [
                (v2/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useIsSellerOnline_seller",
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PresenceResults",
                  "kind": "LinkedField",
                  "name": "presence",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "recentOnlineDate",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isDealer",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InternalWrapper_viewer"
        }
      ]
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useSendPresenceHeartbeats_viewer",
      "selections": [
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useIsSellerOnline_viewer",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "presenceRolloutPercentage",
          "args": [
            {
              "kind": "Literal",
              "name": "key",
              "value": "presenceRolloutPercentage"
            },
            {
              "kind": "Literal",
              "name": "type",
              "value": "graphQLConfig"
            }
          ],
          "kind": "ScalarField",
          "name": "config",
          "storageKey": "config(key:\"presenceRolloutPercentage\",type:\"graphQLConfig\")"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "fe78de0c7e7d1327b73bfe896b828089";

export default node;
