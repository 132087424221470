/**
 * @generated SignedSource<<c138815dc287e0fb6033f65e17715037>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIsSellerOnline_viewer$data = {
  readonly presenceFeatureFlag: boolean | null;
  readonly presenceRolloutPercentage: string | null;
  readonly " $fragmentType": "useIsSellerOnline_viewer";
};
export type useIsSellerOnline_viewer$key = {
  readonly " $data"?: useIsSellerOnline_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsSellerOnline_viewer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useIsSellerOnline_viewer"
};

(node as any).hash = "6437e1fbb8ea77b38dccba7be9949bd6";

export default node;
