import { FC, useContext } from 'react';
import Mask from 'dibs-icons/exports/legacy/Mask';
import MaskDirectLogin from 'dibs-icons/exports/legacy/MaskDirectLogin';
import Person from 'dibs-icons/exports/legacy/PersonProfile';
import PersonPresence from 'dibs-icons/exports/legacy/PersonPresenceProfile';
import classnames from 'classnames';
import { UserContext } from '../UserContext';

import styles from './styles/ResponsiveMenuToggles.scss';

type Props = {
    isMasquerading: boolean;
    isDirectLogin: boolean;
};

const UserIcon: FC<Props> = ({ isMasquerading, isDirectLogin }) => {
    const { isOnline, isPresenceEnabled, isDealer } = useContext(UserContext);

    if (isMasquerading) {
        return <Mask className={styles.navMenuIcon} />;
    } else if (isDirectLogin) {
        return <MaskDirectLogin className={styles.directLoginIcon} />;
    } else if (isPresenceEnabled && isDealer) {
        return (
            <PersonPresence
                className={classnames(styles.navMenuIcon, styles.dibsLayoutUserIcon)}
                isOnline={isOnline}
            />
        );
    } else {
        return <Person className={classnames(styles.navMenuIcon, styles.dibsLayoutUserIcon)} />;
    }
};

export default UserIcon;
