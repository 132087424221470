import type { FC, SyntheticEvent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import serverVars from 'server-vars';

import AccountManager from './AccountManagerComponent';
import { SendFeedbackModal } from './SendFeedbackModal';
import {
    use1stdibsContactModalOpenState,
    useSendFeedbackModalOpenState,
    useVatReportModalOpenState,
} from './helpers';
import GetHelpModalSellerLazy from 'dibs-contact-1stdibs/exports/GetHelpModalSellerLazy';
import { VatReportModal, VAT_REPORT_MODAL_HASH } from '../VatReportModal/VatReportModal';
import { SERVER_VARS } from '../../../../server/constants';

import { CustomNavItem } from './CustomNavItem';
import { NavDropdownItem_seller$data } from './__generated__/NavDropdownItem_seller.graphql';
import { NavDropdownItem_navItem$data } from './__generated__/NavDropdownItem_navItem.graphql';
import type { TPrepareTrackEvent } from '../../helpers/tracking';

import styles from './styles/NavItem.scss';
import typeStyles from '../styles/Type.scss';

type Props = {
    seller: NavDropdownItem_seller$data | null | undefined;
    navItem: NavDropdownItem_navItem$data | null | undefined;
    customNavItem?: CustomNavItem;
    trackEvent?: TPrepareTrackEvent;
};

export const NavDropdownItem: FC<Props> = ({ customNavItem, navItem, seller, trackEvent }) => {
    let type;
    let newWindow;
    let href: string | null | undefined;
    let title;
    let dataTn;
    let badgeText;
    let eventName: string | null | undefined;

    const feedBackSurveyUrl = serverVars.get(SERVER_VARS.constants)?.feedBackSurveyUrl;

    if (customNavItem) {
        ({ type, href, title, dataTn, eventName } = customNavItem);
    } else if (navItem) {
        ({ type, newWindow, href, title, dataTn, badgeText, eventName } = navItem);
    } else {
        throw new Error('navItem or customNavItem must be passed');
    }
    const { isContactModalOpen, setIsContactModalOpen, contactModalOpenHandler } =
        use1stdibsContactModalOpenState(href || '');
    const { isSendFeedbackModalOpen, setIsSendFeedbackModalOpen, sendFeedbackModalOpenHandler } =
        useSendFeedbackModalOpenState();
    const { isVatReportModalOpen, setIsVatReportModalOpen, vatReportModalHandler } =
        useVatReportModalOpenState(href || '');
    const Component = href ? 'a' : 'div';
    const showContact1stdibs = type === 'contact1stdibs';
    const showSendFeedback = type === 'sendFeedback';
    const showVatReportModal = href === VAT_REPORT_MODAL_HASH;
    let handleNavItemClick: unknown;
    if (showContact1stdibs) {
        handleNavItemClick = contactModalOpenHandler;
    } else if (showVatReportModal) {
        handleNavItemClick = vatReportModalHandler;
    } else if (showSendFeedback) {
        handleNavItemClick = sendFeedbackModalOpenHandler;
    }

    if (type === 'divider') {
        return <li className={styles.divider} />;
    }

    const handleClick = (event: SyntheticEvent): void => {
        const isTrackEventAvailable =
            !!href && typeof handleNavItemClick !== 'function' && trackEvent && eventName;

        if (isTrackEventAvailable) {
            trackEvent(eventName || '');
        }

        if (typeof handleNavItemClick === 'function') {
            handleNavItemClick(event);
        }
    };

    return (
        <li className={styles.dropdownItem}>
            <Component
                className={typeStyles[type as keyof typeof typeStyles]}
                onClick={handleClick}
                href={href || undefined}
                rel={newWindow ? 'noopener noreferrer' : undefined}
                target={newWindow ? '_blank' : undefined}
                data-tn={dataTn}
            >
                {type === 'accountManager' ? <AccountManager seller={seller} /> : title}
                {!!showContact1stdibs && (
                    <GetHelpModalSellerLazy
                        isOpen={isContactModalOpen}
                        onClose={() => setIsContactModalOpen(false)}
                        placement="dealerGlobalNav"
                    />
                )}
                {!!showSendFeedback && (
                    <SendFeedbackModal
                        isOpen={isSendFeedbackModalOpen}
                        onClose={() => setIsSendFeedbackModalOpen(false)}
                        feedBackSurveyUrl={feedBackSurveyUrl}
                    />
                )}
                {!!showVatReportModal && (
                    <VatReportModal
                        seller={seller}
                        isOpen={isVatReportModalOpen}
                        onClose={() => {
                            setIsVatReportModalOpen(false);
                        }}
                    />
                )}
                {/* TODO: When DAL is moved to Ferrum, replace next line with <Badge> from dibs-elements */}
                {badgeText && <span className={styles.badge}>{badgeText}</span>}
            </Component>
        </li>
    );
};

export default createFragmentContainer(NavDropdownItem, {
    seller: graphql`
        fragment NavDropdownItem_seller on Seller {
            ...AccountManagerComponent_seller
            ...VatReportModal_seller
        }
    `,
    navItem: graphql`
        fragment NavDropdownItem_navItem on NavBarElement {
            href
            dataTn
            title
            type
            newWindow
            badgeText
            eventName
        }
    `,
});
