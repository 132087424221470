import { FC, useState, ReactNode } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

import { Tooltip } from 'dibs-elements/exports/Tooltip';
import { TooltipHeader } from 'dibs-elements/exports/TooltipHeader';
import { Button } from 'dibs-elements/exports/Button';

// styles
import styles from './styles/CoachmarkTooltip.scss';

type CoachmarkTooltipProps = {
    align?: 'center' | 'left' | 'right';
    direction?: 'top' | 'bottom' | 'left' | 'right';
    onClose?: () => void;
    onNext?: () => void;
    step?: number;
    stepTotal?: number;
    title?: ReactNode;
    children: ReactNode;
};

const CoachmarkTooltip: FC<CoachmarkTooltipProps> = props => {
    const { align = 'center', children, direction = 'top', step = 0, stepTotal = 0, title } = props;
    const [isVisible, setIsVisible] = useState(true);

    return (
        <Tooltip
            align={align}
            closeOnOutsideClick={false}
            direction={direction}
            isVisible={isVisible}
            type="coachmark"
        >
            {title && <TooltipHeader title={title} />}
            {children}
            <div className={styles.footer}>
                {step && stepTotal && step <= stepTotal ? (
                    <div className={styles.page}>
                        <FormattedMessage id="dibs.coachmark.tooltip.step" defaultMessage="Step" />
                        <span>{` ${step}/${stepTotal}`}</span>
                    </div>
                ) : (
                    <p />
                )}
                {step && stepTotal && step < stepTotal ? (
                    <Button
                        onClick={() => {
                            if (props.onNext) {
                                props.onNext();
                            }
                        }}
                        size="medium"
                        type="secondary"
                        dataTn="next-btn"
                        htmlType="button"
                    >
                        <FormattedMessage id="dibs.coachmark.tooltip.next" defaultMessage="Next" />
                    </Button>
                ) : (
                    <Button
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose();
                            }
                            setIsVisible(false);
                        }}
                        size="medium"
                        type="secondary"
                        dataTn="close-btn"
                        htmlType="button"
                    >
                        <FormattedMessage
                            id="dibs.coachmark.tooltip.close"
                            defaultMessage="Close"
                        />
                    </Button>
                )}
            </div>
        </Tooltip>
    );
};

export default CoachmarkTooltip;
