/**
 * @generated SignedSource<<0264b08b8de4586a2e67c044803c544b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopNav_buttons$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"NavBarButtons_buttons">;
  readonly " $fragmentType": "DesktopNav_buttons";
}>;
export type DesktopNav_buttons$key = ReadonlyArray<{
  readonly " $data"?: DesktopNav_buttons$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopNav_buttons">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DesktopNav_buttons",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavBarButtons_buttons"
    }
  ],
  "type": "NavBarButton",
  "abstractKey": null
};

(node as any).hash = "7e815beb86c739befa0f40fca3ee1793";

export default node;
