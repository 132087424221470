type Timeout = ReturnType<typeof setTimeout>;
type ClearTimeoutFn = () => void;

// Adapted from https://stackoverflow.com/questions/29971898/how-to-create-an-accurate-timer-in-javascript
export default function adjustingInterval(callback: () => void, interval: number): ClearTimeoutFn {
    let expected: number = 0;
    let timeout: Timeout | undefined;

    function step(): void {
        const drift = Date.now() - expected;
        callback();
        expected += interval;
        timeout = setTimeout(step, Math.max(0, interval - drift));
    }

    expected = Date.now() + interval;
    timeout = setTimeout(step, interval);

    return () => {
        if (timeout) {
            clearTimeout(timeout);
        }
    };
}
