/**
 * @generated SignedSource<<9b389ca5b9b4fb6efc1046b409b48e71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Nav_notifications$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DalMobileNav_notifications" | "DesktopNav_notifications">;
  readonly " $fragmentType": "Nav_notifications";
};
export type Nav_notifications$key = {
  readonly " $data"?: Nav_notifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav_notifications">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Nav_notifications",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DalMobileNav_notifications"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopNav_notifications"
    }
  ],
  "type": "DealerNavBarBadges",
  "abstractKey": null
};

(node as any).hash = "f3dfcbcf4ea746678a42db3cedafdc19";

export default node;
