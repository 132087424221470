/**
 * @generated SignedSource<<d116863e3589b5c6c5010537cf57433d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Nav_links$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DalMobileNav_links" | "DesktopNav_links">;
  readonly " $fragmentType": "Nav_links";
};
export type Nav_links$key = {
  readonly " $data"?: Nav_links$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav_links">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Nav_links",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DalMobileNav_links"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopNav_links"
    }
  ],
  "type": "NavBarLinks",
  "abstractKey": null
};

(node as any).hash = "58bca011b4fcdedc6e348ad8192ccb06";

export default node;
