import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles/ExpandingList.scss';

const STATES = {
    OPENED: 'OPENED',
    CLOSED: 'CLOSED',
    TRANSITION: null,
};
export function ExpandingList({ dataTn, open, className = '', listClassName = '', children }) {
    const ref = useRef(null);
    const [direction, setDirection] = useState(STATES.CLOSED);

    // the document needs to render the transition based on the derived height logic
    // below otherwise React makes DOM changes too quickly and the intermediate step
    // (height change) is "skipped"
    if ((direction === STATES.CLOSED && open) || (direction === STATES.OPENED && !open)) {
        setTimeout(() => {
            setDirection(STATES.TRANSITION);
        }, 5);
    }
    // if the ExpandingList is closed and not about to transition, do not render.
    // otherwise when toggling between two expanding lists in the same parent
    // the height of the parent will briefly exceed the height of
    // the two ExpandingLists being toggled, causing a wave-like animation
    const display = direction === STATES.CLOSED && !open ? 'none' : 'block';
    const innerHeight = (ref.current && ref.current.offsetHeight) || 0;

    let height = 0;
    // set height to auto so that the element can continue to expand if children change height
    // after the ExpandingList has opened
    if (open && direction === STATES.OPENED) {
        height = 'auto';
    }
    // if the ExpandingList is about to open (open prop is true and direction is CLOSED
    // or about to close (direction is OPENED and open prop is false), give the container
    // and explicit height to transition from
    else if (open || direction === STATES.OPENED) {
        height = innerHeight;
    }

    // hide scrollbar on expandingList content when transitioning
    const overflowY = height === 'auto' ? null : 'hidden';

    return (
        <div
            data-tn={dataTn}
            onTransitionEnd={() => {
                if (height !== 'auto') {
                    setDirection(open ? STATES.OPENED : STATES.CLOSED);
                }
            }}
            className={classnames(styles.container, className)}
            style={{
                height,
                overflowY,
                display,
            }}
        >
            <ul className={classnames(styles.list, listClassName)} ref={ref}>
                {children}
            </ul>
        </div>
    );
}

ExpandingList.propTypes = {
    dataTn: PropTypes.string.isRequired,
    open: PropTypes.bool,
    className: PropTypes.string,
    listClass: PropTypes.string,
    children: PropTypes.node,
    listClassName: PropTypes.string,
};
