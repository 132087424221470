import { useContext, FC } from 'react';
import classNames from 'classnames';
import get from 'lodash.get';

import UserIcon from './UserIcon';
import { UserContext } from '../UserContext';
import { isDirectlyLoggedInAsUser } from 'dibs-cookie-jar';

import styles from './styles/UserMenuToggle.scss';

const UserMenuToggle: FC = () => {
    const { isDealer, loginDealer, seller, masqueradeSeller, user } = useContext(UserContext);

    const isDealerLayout = isDealer || loginDealer;

    function nameOrEmail(): string {
        if (masqueradeSeller) {
            return get(masqueradeSeller, 'sellerProfile.company');
        } else if (seller) {
            return get(seller, 'sellerProfile.company');
        } else if (user) {
            const displayName = get(user, 'profile.displayName');
            const email = get(user, 'profile.email');
            return displayName || email;
        }
        return '';
    }
    return (
        <>
            <div
                className={classNames(styles.truncateCompanyName, {
                    [styles.hidden]: isDealerLayout,
                })}
            >
                {nameOrEmail()}
            </div>
            <div className={styles.iconContainer}>
                <UserIcon
                    isMasquerading={!!masqueradeSeller}
                    isDirectLogin={isDirectlyLoggedInAsUser(document.cookie)}
                />
            </div>
        </>
    );
};

export default UserMenuToggle;
