import dateFnsAddMonths from 'date-fns/addMonths';
import dateFnsSubMonths from 'date-fns/subMonths';
import dateFnsGetMonth from 'date-fns/getMonth';
import addHours from 'date-fns/addHours';
import isBeforeDay from 'date-fns/isBefore';
import isAfterDay from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import isDate from 'date-fns/isDate';
import setDay from 'date-fns/setDay';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import getDay from 'date-fns/getDay';
import isSameMonth from 'date-fns/isSameMonth';
import isValid from 'date-fns/isValid';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import serverVars from 'server-vars';

const locale = serverVars.get('locale') || 'en-US';

export function addMonths(date: Date, amount: number): Date {
    return dateFnsAddMonths(date, amount);
}

export function subMonths(date: Date, amount: number): Date {
    return dateFnsSubMonths(date, amount);
}

export function getMonth(date: Date): number {
    return dateFnsGetMonth(date);
}

export function getFirstDayOfWeek(): number {
    if (locale === 'en-US') {
        return 0;
    } else {
        return 1;
    }
}

export function formatDate(date: Date, dateFormat?: Intl.DateTimeFormatOptions): string {
    if (!dateFormat) {
        dateFormat = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    }
    return isValid(date) ? new Intl.DateTimeFormat(locale, dateFormat).format(date) : '';
}

// returns date with hours set at noon, if there is no date argument it returns today
export function getDate(date?: Date): Date {
    if (!date || !isDate(date)) {
        date = new Date();
    }
    return addHours(startOfDay(date), 12);
}

export function getNamesOfWeekDays(firstDayOfWeek: number): Array<string> {
    const weekDays = [];

    for (let i = 0; i < 7; i += 1) {
        const day = setDay(getDate(), (i + firstDayOfWeek) % 7);
        weekDays.push(formatDate(day, { weekday: 'short' }).slice(0, -1));
    }

    return weekDays;
}

export function isCurrentMonth(focusedDate: Date): boolean {
    const todaysDate = getDate();

    return isSameMonth(focusedDate, todaysDate);
}

export function getFormattedMonthTitle(focusedDate: Date): string {
    return new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'long' }).format(focusedDate);
}

export interface CalendarDayType {
    day: Date;
    disabled: boolean | null;
    isToday: boolean | null;
    selected: boolean | null;
    isOutside: boolean | null;
    onDayClick?: (day: Date) => void;
}

// https://github.com/urobots/react-dates-fns/blob/master/src/utils/getCalendarMonthWeeks.js
export function getCalendarMonthWeeks(
    month: Date | number,
    firstDayOfWeek: number,
    disablePastDates: boolean,
    disableFutureDates: boolean,
    selectedDate: Date | null,
    firstAvailableDate: Date | null | undefined,
    lastAvailableDate: Date | null | undefined
): Array<Array<CalendarDayType>> {
    const firstOfMonth = startOfMonth(month);
    const lastOfMonth = endOfMonth(month);

    const prevDays = (getDay(firstOfMonth) + 7 - firstDayOfWeek) % 7;
    const nextDays = (firstDayOfWeek + 6 - getDay(lastOfMonth)) % 7;
    const firstDay = subDays(firstOfMonth, prevDays);
    const lastDay = addDays(lastOfMonth, nextDays);

    const totalDays = differenceInCalendarDays(lastDay, firstDay) + 1;
    const weeksInMonth: Array<Array<CalendarDayType>> = [];

    firstAvailableDate = firstAvailableDate ? startOfDay(firstAvailableDate) : null;
    lastAvailableDate = lastAvailableDate ? startOfDay(lastAvailableDate) : null;

    for (let i = 0; i < totalDays; i += 1) {
        if (i % 7 === 0) {
            weeksInMonth.push([]);
        }

        const day: Date = addDays(firstDay, i);

        const todaysDate = startOfDay(new Date());
        const isBeforeToday = isBeforeDay(day, todaysDate);
        const isAterToday = isAfterDay(day, todaysDate);
        const disabled =
            (disablePastDates && isBeforeToday) ||
            (disableFutureDates && isAterToday) ||
            (firstAvailableDate && isBeforeDay(day, firstAvailableDate)) ||
            (lastAvailableDate && isAfterDay(day, lastAvailableDate));
        const isToday = isSameDay(day, todaysDate);
        const selected = selectedDate ? isSameDay(day, selectedDate) : null;
        const isOutside = !isSameMonth(day, firstOfMonth);

        weeksInMonth[weeksInMonth.length - 1].push({
            day,
            disabled,
            isToday,
            selected,
            isOutside,
        });
    }

    return weeksInMonth;
}

/*
    helpful when you want a date to be saved in DB with the time and date chosen client side but with the ET timezone, e.g.:
    - selectedDate: Sat Aug 27 2022 00:00:00 GMT-0800 (Alaska Daylight Time)
    - offsetted: Fri Aug 26 2022 20:00:00 GMT-0800 (Alaska Daylight Time)

    This will result in the date being saved in the DB as: 2022-08-27T00:00:00.000-04:00 (or -05:00 depending if it's EST or EDT) keeping the original midnight time and only changing the timezone.

    Not ideal for displaying to UI, as the day (hence month and year) of the offsetted date could be different from the selectedDate (look at the above example) depending on the timezone of the user that is viewing the page.
*/
export const toET = (selectedDate: Date): Date => {
    const tzDate = new Date(
        selectedDate.toLocaleString('en-US', {
            timeZone: 'America/New_York',
        })
    );
    const diff = Math.round(selectedDate.getTime() - tzDate.getTime());

    const offsetted = new Date(selectedDate.getTime() + diff);

    return offsetted;
};
