import { useEffect, useRef, useState } from 'react';
export default () => {
    const [width, setWidth] = useState(window.innerWidth);
    const resizeTimeout = useRef();

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        function throttle() {
            if (!resizeTimeout.current) {
                resizeTimeout.current = setTimeout(function () {
                    resizeTimeout.current = null;
                    handleResize();
                }, 100);
            }
        }
        window.addEventListener('resize', throttle);
        handleResize();
        return () => {
            window.removeEventListener('resize', throttle);
        };
    });
    return width;
};
