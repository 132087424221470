export const SELLER_ONBOARDING = '/dealers/account-setup/' as const;
export const SELLER_SUPPORT = '/dealers/support/' as const;
export const ITEM_UPLOAD = '/dealers/item/' as const;
export const VIDEO_UPLOAD = '/dealers/aws-signed-request/' as const;
export const SELLER_LOGIN = '/login/dealer/' as const;
export const INTERNAL_LOGIN = '/login/internal/' as const;
export const SELLER_ROOT = '/dealers' as const;
export const INTERNAL_ROOT = '/internal' as const;
export const USER_TYPE_INTERNAL = 'internal' as const;
export const USER_TYPE_SELLER = 'seller' as const;
export const PERMISSION_EDITORIAL_CMS_ACCESS = 'EDITORIAL_CMS_ACCESS' as const;
export const PERMISSION_PHOTO_ACCESS = 'PHOTO_ACCESS' as const;
export const PERMISSION_INTERNAL_ADMIN_USER = 'INTERNAL_ADMIN_USER' as const;
export const PERMISSION_BASIC_INTERNAL = 'BASIC_INTERNAL' as const;
export const PERMISSION_DEALER_ACCESS = 'DEALER_ACCESS' as const;
export const SELLER_TOKEN_COOKIE = 'sellerToken' as const;
export const USER_TOKEN_COOKIE = 'userToken' as const;
export const EMAIL_TOKEN_COOKIE = 'userEmailToken' as const;
export const LEGACY_ADMIN_COOKIE = 'prck' as const;
export const EDITORIAL_CMS_ACCESS = 'ed_reg' as const;
export const AUTH_MAX_AGE_KEY = 'INTERNAL_ADMIN' as const;
export const MASQUERADE_SELLER_COOKIE = 'masqueradeSeller' as const;
export const MASQUERADE_BUYER_COOKIE = 'masqueradeBuyer' as const;
export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';
export const TABLET = 'tablet';

export const SERVER_VARS = {
    constants: 'constants',
    cookieDomain: 'cookieDomain',
    DD_RUM: 'DD_RUM',
    freshLogin: 'freshLogin',
    intlLinkingMode: 'intlLinkingMode',
    isDesktop: 'isDesktop',
    isMobile: 'isMobile',
    isTablet: 'isTablet',
    locale: 'locale',
    loginDealer: 'loginDealer',
    loginInternal: 'loginInternal',
    masqueradeSeller: 'masqueradeSeller',
    messages: 'messages',
    NODE_GRAPHQL_SUBSCRIPTIONS_URL: 'NODE_GRAPHQL_SUBSCRIPTIONS_URL',
    NODE_PROM_ANALYTICS_ENDPOINT: 'NODE_PROM_ANALYTICS_ENDPOINT',
    supportedLocales: 'supportedLocales',
    supportHost: 'supportHost',
    sellerData: 'sellerData',
    sellerSupportHost: 'sellerSupportHost',
    settings: 'settings',
    tracking: 'tracking',
    userData: 'userData',
} as const;
