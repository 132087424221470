/**
 * @generated SignedSource<<7ac8a395ca28b0fe6cc63f91c7c5c880>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSendPresenceHeartbeats_viewer$data = {
  readonly presenceFeatureFlag: boolean | null;
  readonly " $fragmentType": "useSendPresenceHeartbeats_viewer";
};
export type useSendPresenceHeartbeats_viewer$key = {
  readonly " $data"?: useSendPresenceHeartbeats_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSendPresenceHeartbeats_viewer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSendPresenceHeartbeats_viewer"
};

(node as any).hash = "9ccb749e0f2765ccc3d8e7f92960880c";

export default node;
