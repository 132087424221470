import { useState, useRef, useContext, useEffect, FC, ReactNode, MouseEventHandler } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';

import { hasKey } from 'dibs-ts-utils/exports/hasKey';
import { localStorage } from 'dibs-browser-storage';
import dibsCss from 'dibs-css';
import serverVars from 'server-vars';

import GetHelpModalSellerLazy from 'dibs-contact-1stdibs/exports/GetHelpModalSellerLazy';
import AccountManager from './AccountManagerComponent';
import { prepareTrackEvent, trackNavClick } from '../../helpers/tracking';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import useRefPropertyFactory from '../../hooks/useRefPropertyFactory';
import { ExpandingList } from '../ExpandingList';
import { NavIcon, ICON_TYPES } from './NavIcon';
import { VatReportModal, VAT_REPORT_MODAL_HASH } from '../VatReportModal/VatReportModal';
import styles from './styles/NavItem.scss';
import typeStyles from '../styles/Type.scss';
import { Tooltip } from '../Tooltip';
import { Badge } from '../Badge';
import {
    toDataTn,
    formatNotificationCount,
    use1stdibsContactModalOpenState,
    useVatReportModalOpenState,
    useSendFeedbackModalOpenState,
} from './helpers';
import NavDropdownItem from './NavDropdownItem';
import { UserContext } from '../../UserContext';
import { SERVER_VARS } from '../../../../server/constants';

import { NavItem_notifications$data } from './__generated__/NavItem_notifications.graphql';
import { NavItem_navItem$data } from './__generated__/NavItem_navItem.graphql';
import { NavItem_seller$data } from './__generated__/NavItem_seller.graphql';

import { CustomNavItem } from './CustomNavItem';
import { SendFeedbackModal } from './SendFeedbackModal';
import AppStoreDark from 'dibs-icons/exports/legacy/AppStoreDark';

/**
 * On mobile browsers, the first click of an element fires the mouseEnter event
 * and the click event in rapid succession. To handle this, we are storing the
 * datestamp every time the user triggers a mouseEnter event, and if a click happens
 * within less than this number of milliseconds, we don't trigger the click handler.
 */
const MAX_CLICK_MOUSENTER_DIFF = 300;

const useOffsetWidth = useRefPropertyFactory({
    properties: 'offsetWidth',
    event: 'resize',
});

type TNavItemBadgeProps = {
    type: string | null | undefined;
    notifications: NavItem_notifications$data | null | undefined;
};

const NavItemBadge: FC<TNavItemBadgeProps> = ({ type, notifications }) => {
    const notificationsConfig = {
        ordersIcon: { count: notifications?.orders || 0, dataTn: 'orders' },
        messageIcon: { count: notifications?.messages || 0, dataTn: 'messages' },
        listingsIcon: { count: notifications?.listings || 0, dataTn: 'listings' },
    };

    if (type === 'advertisingIcon') {
        const hasSeenAdvertisingPage = localStorage.getItem('SELLER_ADVERTISING_PAGE_SEEN');
        return hasSeenAdvertisingPage ? null : (
            <span className={styles.advertisingBadge} data-tn="navbar-badge-advertising" />
        );
    }

    const config =
        type && hasKey(notificationsConfig, type)
            ? notificationsConfig[type]
            : { count: 0, dataTn: '' };
    const [count, isCeil] = formatNotificationCount(config.count || 0);
    return (count as number) > 0 || isCeil ? (
        <Badge
            content={count}
            padding={isCeil ? { right: 2 } : null}
            dataTn={`navbar-badge-${toDataTn(config.dataTn)}`}
        />
    ) : null;
};

type Props = {
    badge?: ReactNode;
    isMobile: boolean;
    className?: string;
    customNavItem?: CustomNavItem;
    navItem: NavItem_navItem$data | null | undefined;
    notifications: NavItem_notifications$data | null | undefined;
    seller: NavItem_seller$data | null | undefined;
    renderAsDiv?: boolean;
    children?: ReactNode;
};

type NavItemItems = NavItem_navItem$data['items'];

const NavItem: FC<Props> = ({
    isMobile,
    children,
    notifications,
    className,
    customNavItem,
    navItem,
    seller,
    renderAsDiv,
}) => {
    let type;
    let exactLocation;
    let title: ReactNode;
    let href;
    let dataTn;
    let newWindow;
    let eventName: string | undefined | null = null;
    let interactionType: string | undefined | null = null;
    let items: NavItemItems;

    const feedBackSurveyUrl = serverVars.get(SERVER_VARS.constants)?.feedBackSurveyUrl;

    if (customNavItem) {
        ({ type, dataTn, title, href, exactLocation, eventName, interactionType } = customNavItem);
    } else if (navItem) {
        ({
            type,
            title,
            href,
            dataTn,
            newWindow,
            items,
            exactLocation,
            eventName,
            interactionType,
        } = navItem);
    } else {
        throw new Error('navItem or customNavItem must be passed');
    }

    const { isDealer, hasNotifications, setHasNotifications } = useContext(UserContext);
    const { isContactModalOpen, setIsContactModalOpen, contactModalOpenHandler } =
        use1stdibsContactModalOpenState(href || '');
    const { isSendFeedbackModalOpen, setIsSendFeedbackModalOpen, sendFeedbackModalOpenHandler } =
        useSendFeedbackModalOpenState();
    const { isVatReportModalOpen, setIsVatReportModalOpen, vatReportModalHandler } =
        useVatReportModalOpenState(href || '');

    useEffect(() => {
        if (notifications) {
            for (const notification in notifications) {
                if (
                    notifications[notification as keyof typeof notifications] &&
                    !hasNotifications
                ) {
                    setHasNotifications(true);
                }
            }
        }
    }, [notifications, hasNotifications, setHasNotifications]);

    const noHover = !isDealer;
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
    const [lastMouseOpen, setLastMouseOpen] = useState(0);
    const wrapperRef = useRef<HTMLLIElement>(null);
    const offsetWidth = useOffsetWidth(wrapperRef);

    useDetectOutsideClick(wrapperRef, () => setSubMenuOpen(false));

    if (type === 'divider') {
        return <li className={styles.divider} />;
    }
    const badge =
        isDealer && type ? <NavItemBadge type={type} notifications={notifications} /> : null;
    let atNavLocation = false;

    if (href) {
        atNavLocation = exactLocation
            ? window.location.pathname.replace('/', '') === href.replace('/', '')
            : window.location.pathname.includes(href);
    }

    const showContact1stdibs = type === 'supportLink';
    const showSendFeedback = type === 'sendFeedback';
    const showVatReportModal = href === VAT_REPORT_MODAL_HASH;

    function renderChildren(): ReactNode {
        const mappedItems = (items || []).map((item, i) => {
            return (
                !!item && (
                    <NavDropdownItem
                        seller={seller}
                        key={`dropdown-${i}`}
                        navItem={item}
                        trackEvent={prepareTrackEvent(eventName, interactionType)}
                    />
                )
            );
        });

        if (!(children || mappedItems.length)) {
            return null;
        } else if (isMobile) {
            return (
                <ExpandingList dataTn={title + 'ExpandingList'} open={isSubMenuOpen}>
                    {children}
                    {mappedItems}
                </ExpandingList>
            );
        } else {
            return (
                <Tooltip
                    dataTn={title + 'ToolTip'}
                    container="ul"
                    offsetWidth={offsetWidth}
                    className={styles.navTooltip}
                >
                    {children}
                    {mappedItems}
                </Tooltip>
            );
        }
    }
    const renderedChildren = renderChildren();
    let InteractiveElement: 'a' | 'button' | 'div' = 'div';
    if (!renderAsDiv && (href || renderedChildren)) {
        InteractiveElement = href ? 'a' : 'button';
    }

    const handleClick: MouseEventHandler = e => {
        const clickDelta = new Date().getTime() - lastMouseOpen;
        const didTouchTriggerMouseOver = clickDelta <= MAX_CLICK_MOUSENTER_DIFF;
        const isNotMouseOrTouchClick = !e.clientX && !e.clientY;
        if (
            (didTouchTriggerMouseOver || isMobile || noHover || isNotMouseOrTouchClick) &&
            renderedChildren
        ) {
            e.preventDefault();
            setSubMenuOpen(prev => !prev);
        } else if (eventName) {
            trackNavClick({ action: eventName, label: eventName, interactionType });
        }
    };

    const handleMouseEnter = (): void => {
        setLastMouseOpen(new Date().getTime());
    };

    const icon =
        type && ICON_TYPES.includes(type) ? (
            <NavIcon type={type} badge={!isMobile && badge} />
        ) : null;

    let handler;
    if (showContact1stdibs) {
        handler = contactModalOpenHandler;
    } else if (showVatReportModal) {
        handler = vatReportModalHandler;
    } else if (showSendFeedback) {
        handler = sendFeedbackModalOpenHandler;
    } else {
        handler = handleClick;
    }

    return (
        <>
            <li
                ref={wrapperRef}
                data-tn={dataTn}
                onBlur={e => {
                    if (
                        wrapperRef.current &&
                        !wrapperRef.current.contains(e.relatedTarget as $TSFixMe)
                    ) {
                        setSubMenuOpen(false);
                    }
                }}
                className={classnames(styles.item, className, {
                    [styles.open]: isSubMenuOpen,
                    [styles.expandingListElement]: isMobile,
                    [styles.noHover]: noHover,
                    [styles.selected]: atNavLocation,
                    [styles.dealerNavItem]: isDealer,
                })}
            >
                <InteractiveElement
                    href={href || undefined}
                    aria-haspopup={!!renderedChildren}
                    aria-expanded={isSubMenuOpen}
                    rel={newWindow ? 'noopener noreferrer' : undefined}
                    target={newWindow ? '_blank' : undefined}
                    onClick={handler}
                    onMouseEnter={handleMouseEnter}
                    className={classnames(
                        styles.interactiveElement,
                        typeStyles[type as keyof typeof typeStyles],
                        {
                            [styles.withIcon]: !!icon,
                        }
                    )}
                    data-tn={
                        typeof title === 'string' ? `navbar-dropdown-${toDataTn(title)}` : null
                    }
                >
                    {icon}
                    {type === 'accountManager' ? <AccountManager seller={seller} /> : title}
                    {!!showContact1stdibs && (
                        <GetHelpModalSellerLazy
                            isOpen={isContactModalOpen}
                            onClose={() => setIsContactModalOpen(false)}
                            placement="dealerGlobalNav"
                        />
                    )}
                    {!!showSendFeedback && (
                        <SendFeedbackModal
                            isOpen={isSendFeedbackModalOpen}
                            onClose={() => setIsSendFeedbackModalOpen(false)}
                            feedBackSurveyUrl={feedBackSurveyUrl}
                        />
                    )}
                    {!!showVatReportModal && (
                        <VatReportModal
                            seller={seller}
                            isOpen={isVatReportModalOpen}
                            onClose={() => setIsVatReportModalOpen(false)}
                        />
                    )}
                </InteractiveElement>
                {renderedChildren}
                {isMobile && badge}
            </li>
            {isMobile && type === 'downloadSellerApp' && (
                <a href={href || undefined} target="_blank" rel="noreferrer">
                    <AppStoreDark className={classnames(dibsCss.hLarge, styles.appStoreIcon)} />
                </a>
            )}
        </>
    );
};

export default createFragmentContainer(NavItem, {
    seller: graphql`
        fragment NavItem_seller on Seller {
            ...NavDropdownItem_seller
            ...AccountManagerComponent_seller
            ...VatReportModal_seller
        }
    `,
    notifications: graphql`
        fragment NavItem_notifications on DealerNavBarBadges {
            listings
            orders
            messages
        }
    `,
    navItem: graphql`
        fragment NavItem_navItem on NavBarElement {
            href
            dataTn
            title
            type
            newWindow
            exactLocation
            eventName
            interactionType
            ... on NavBarList {
                items {
                    ...NavDropdownItem_navItem
                }
            }
        }
    `,
});
