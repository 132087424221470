import { useState, useContext, useRef, useEffect, FC } from 'react';
import * as React from 'react';
import { createFragmentContainer, graphql, RelayProp } from 'react-relay/legacy';
import Logo from 'dibs-logo/exports/Logo';
import { breakpoints } from 'dibs-sassy/exports/breakpoints';
import classnames from 'classnames';

import InternalWrapper from './Nav/InternalWrapper';
import DealerWrapper from './Nav/DealerWrapper';
import ResponsiveMenuToggles from './ResponsiveMenuToggles';
import { CustomHeaderMessage } from './CustomHeaderMessage';
import { UserContext } from '../UserContext';
import { trackNavClick } from '../helpers/tracking';
import useWindowWidth from '../hooks/useWindowWidth';
import { useBrandLink } from './userMenuOptions';
import styles from './styles/Header.scss';
import utilStyles from './styles/utils.scss';
import { isDirectlyLoggedInAsUser } from 'dibs-cookie-jar';
import { useSendPresenceHeartbeats } from 'dibs-presence/exports/useSendPresenceHeartbeats';
import { useIsSellerOnline } from 'dibs-presence/exports/useIsSellerOnline';
import { trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';
import { NavHeader_viewer$data } from './__generated__/NavHeader_viewer.graphql';

const DEALER_TABLET_MAX_WIDTH = 1121;

type Props = {
    viewer: NavHeader_viewer$data | null | undefined;
    customBannerText?: React.ReactNode;
    refetchIndex: number;
    relay: RelayProp;
};

const NavHeader: FC<Props> = props => {
    const { viewer, relay } = props;

    const customBannerMessage = props.customBannerText || viewer?.dealerNavBar?.bannerText || null;
    const defaultUserContext = useContext(UserContext);
    const [userContextState, setUserContextState] = useState(defaultUserContext);
    const setHasNotifications = (cond: boolean): void =>
        setUserContextState(prev => ({ ...prev, hasNotifications: cond }));
    const { isDealer, userOrSeller, masqueradeSeller, loginDealer } = userContextState;
    const seller = viewer?.seller;

    useSendPresenceHeartbeats({
        environment: relay.environment,
        viewer: viewer,
        seller: seller || null,
        isInternalUser: !isDealer,
    });
    const isOnline = useIsSellerOnline({
        environment: relay.environment,
        viewer: viewer || null,
        seller: seller,
    });
    const userMenuButtonRef = useRef<HTMLLIElement>(null);
    const windowWidth = useWindowWidth();

    const isDealerLayout = isDealer || loginDealer;
    const mobileBreakPoint = isDealerLayout
        ? DEALER_TABLET_MAX_WIDTH
        : breakpoints.tabletLandscape.min;
    const isMobile = windowWidth < mobileBreakPoint;

    const [menuState, setMenuState] = useState({ userMenu: false, navMenu: false });
    const brandLink = useBrandLink();

    useEffect(() => {
        if (isDealer) {
            trackAbTestV2Variant('seller-aa-test-3');
        }
    }, [isDealer]);

    const { userMenu: openUserMenu, navMenu: openNavBar } = menuState;
    const isCustomHeader = customBannerMessage || loginDealer;
    const customHeader = isCustomHeader ? (
        <CustomHeaderMessage>{customBannerMessage}</CustomHeaderMessage>
    ) : null;

    const UserOrSellerNav = isDealerLayout ? DealerWrapper : InternalWrapper;
    const navType = isDealerLayout ? 'dealer' : 'internal';

    return (
        <UserContext.Provider
            value={{
                ...userContextState,
                setHasNotifications,
                isOnline,
                isPresenceEnabled: !!viewer?.presenceFeatureFlag,
            }}
        >
            <nav
                data-navtype={navType}
                className={classnames({
                    [styles.dealerNav]: isDealer,
                    [styles.internalNav]: !isDealer,
                })}
                data-tn="top-navbar"
                id="js-header"
            >
                <div className={styles.navHeading}>
                    <a
                        data-tn="navbarBrandLink"
                        onClick={() =>
                            isDealer ? trackNavClick({ action: 'logo', label: 'none' }) : null
                        }
                        className={classnames(styles.brand, 'gtm-header-logo')}
                        href={brandLink}
                    >
                        <Logo className={styles.logo} />
                    </a>
                    {isMobile && (
                        <>
                            {customHeader ? (
                                customHeader
                            ) : (
                                <div className={utilStyles.flexSpacer} />
                            )}
                            {userOrSeller && (
                                <ResponsiveMenuToggles
                                    isCustomHeader={!!customHeader}
                                    userMenuButtonRef={userMenuButtonRef}
                                    isMasquerading={!!masqueradeSeller}
                                    isDirectLogin={isDirectlyLoggedInAsUser(document.cookie)}
                                    menuState={menuState}
                                    setMenuState={setMenuState}
                                />
                            )}
                        </>
                    )}
                </div>
                {(userOrSeller || loginDealer) && (
                    <UserOrSellerNav
                        viewer={viewer}
                        isMobile={isMobile}
                        openNavBar={openNavBar}
                        refetchIndex={props.refetchIndex}
                        customHeader={customHeader || null}
                        openUserMenu={openUserMenu}
                    />
                )}
                <div id="nav-bottom-marker" />
            </nav>
        </UserContext.Provider>
    );
};

export default createFragmentContainer(NavHeader, {
    viewer: graphql`
        fragment NavHeader_viewer on Viewer {
            presenceFeatureFlag: featureFlag(feature: "presence")
            ... @include(if: $isDealer) {
                ...DealerWrapper_viewer
                dealerNavBar(
                    sellerPk: $sellerPk
                    dotComHost: $dotComHost
                    hyperwalletLink: $hyperwalletLink
                    customBannerText: $customBannerText
                    userId: $userId
                ) {
                    bannerText
                }
            }
            seller(sellerId: $sellerPk) @include(if: $hasSellerPk) {
                serviceId
                sellerPreferences {
                    showOnlineStatus
                }
                ...useSendPresenceHeartbeats_seller
                ...useIsSellerOnline_seller
            }
            ... @skip(if: $isDealer) {
                ...InternalWrapper_viewer
            }
            ...useSendPresenceHeartbeats_viewer
            ...useIsSellerOnline_viewer
        }
    `,
});
