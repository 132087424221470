import { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { useIntl } from 'dibs-react-intl';

import { usePageVisibility } from 'dibs-elements/exports/usePageVisibility';
import { localStorage } from 'dibs-browser-storage';
import { trackTabAlertAction } from 'dibs-browser-notifications/exports/tracking';

import { UserContext } from '../UserContext';
import getNotificationMessage from '../helpers/getNotificationMessage';
import adjustingInterval from '../helpers/adjustingInterval';
import { MilestoneNotificationType } from './useSellerNotifications';

export type NotificationCounts = Partial<Record<MilestoneNotificationType, number>>;

const NOTIFICATION_INTERVAL = 2000;
const SHOW_SELLER_TAB_NOTIFICATIONS = 'showSellerTabNotifications';
const INITIAL_NOTIFICATION_STATE: NotificationCounts = {
    MESSAGE: 0,
    OFFER: 0,
    ORDER: 0,
};

const useTabNotifications = (): ((
    notificationType: MilestoneNotificationType,
    buyerName?: string | null
) => void) => {
    const intl = useIntl();
    const { isDealer } = useContext(UserContext);
    const originalDocumentTitle = useRef<string>(document.title);
    const notificationInterval = useRef<(() => void) | null>(null);
    const updateTitle = useRef(() => {});
    const [notificationCounts, setNotificationCounts] = useState(INITIAL_NOTIFICATION_STATE);
    const [lastNotificationBuyerName, setLastNotificationBuyerName] = useState<
        string | undefined | null
    >(null);
    const isVisible = usePageVisibility();

    const clearNotifications = (): void => {
        document.title = originalDocumentTitle.current;
        setNotificationCounts(INITIAL_NOTIFICATION_STATE);
        if (notificationInterval.current) {
            notificationInterval.current();
            notificationInterval.current = null;
        }
    };

    useEffect(() => {
        if (!isDealer) {
            return () => {};
        }

        const handleStorageChange = (): void => {
            const showTabNotifications = localStorage.getItem(SHOW_SELLER_TAB_NOTIFICATIONS);
            if (showTabNotifications && isVisible) {
                localStorage.setItem(SHOW_SELLER_TAB_NOTIFICATIONS, false);
            }
            clearNotifications();
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [isVisible, isDealer]);

    useEffect(() => {
        if (!isDealer) {
            return;
        }

        if (isVisible) {
            if (notificationInterval.current) {
                trackTabAlertAction('Clicked');
            }
            localStorage.setItem(SHOW_SELLER_TAB_NOTIFICATIONS, false);
            clearNotifications();
        } else {
            localStorage.setItem(SHOW_SELLER_TAB_NOTIFICATIONS, true);
        }
    }, [isVisible, isDealer]);

    updateTitle.current = () => {
        const newTitle = getNotificationMessage({
            intl,
            notificationCounts,
            name: lastNotificationBuyerName,
        });
        document.title =
            document.title === originalDocumentTitle.current && newTitle
                ? newTitle
                : originalDocumentTitle.current;
    };

    const onNewMilestone = useCallback(
        (notificationType: MilestoneNotificationType, buyerName?: string | null) => {
            const showTabNotifications = localStorage.getItem(SHOW_SELLER_TAB_NOTIFICATIONS);

            if (!notificationInterval.current && !isVisible && showTabNotifications) {
                setLastNotificationBuyerName(buyerName);
                setNotificationCounts({
                    ...notificationCounts,
                    [notificationType]: (notificationCounts[notificationType] || 0) + 1,
                });
                trackTabAlertAction('Displayed');
                notificationInterval.current = adjustingInterval(
                    () => updateTitle.current(),
                    NOTIFICATION_INTERVAL
                );
            }
        },
        [notificationCounts, isVisible]
    );

    return onNewMilestone;
};

export default useTabNotifications;
