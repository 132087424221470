/**
 * @generated SignedSource<<4dbf801dded09d2f7b8de6a133598e14>>
 * @relayHash 70bd5c664e8a2cf216cf73939de33fd1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/488.0.0-2024-11-12T17:14:07.765Z/newMilestoneSellerSubscription

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type ConversationMemberType = "BUYER" | "EXTERNAL" | "INTERNAL" | "SELLER" | "SYSTEM" | "%future added value";
export type MilestoneType = "ABANDON_MAKE_OFFER" | "AUCTION_WON_TRANSACTION" | "AUCTION_WON_TRANSACTION_EXPIRED" | "BUYER_CALL_INITIATED" | "BUYER_COUNTER_OFFER" | "BUYER_OFFER_ACCEPTED_BY_DEALER_POST_CHECKOUT" | "BUYER_OFFER_EXPIRED_BUYER_INACTION_POST_CHECKOUT" | "BUYER_OFFER_EXPIRED_DEALER_INACTION_POST_CHECKOUT" | "BUYER_QUOTE_REQUESTED_PRE_CHECKOUT" | "BUYER_SUPPORT_REQUEST_MESSAGE" | "BUYER_SUPPORT_REQUEST_RESOLUTION" | "CHECKOUT_BUYER_INITIATED_NEGOTIATION" | "CHECKOUT_DEALER_INITIATED_NEGOTIATION" | "CHECKOUT_FULL_PRICE" | "CHECKOUT_FULL_PRICE_SENT_TO_DEALER" | "CHECKOUT_NEGOTIATION_SENT_TO_DEALER" | "CHECKOUT_NEGOTIATION_WITH_RESERVE" | "CONVERSATION_MESSAGE" | "DEALER_CALL_INITIATED" | "DEALER_CALL_INVITE" | "DEALER_COUNTER_OFFER" | "DEALER_INITIATED_NEGOTIATION_PRE_CHECKOUT" | "DEALER_OFFER_ACCEPTED_BY_BUYER_POST_CHECKOUT" | "DEALER_OFFER_AMENDED_POST_CHECKOUT" | "DEALER_OFFER_AMENDED_PRE_CHECKOUT" | "DEALER_OFFER_EXPIRED_PRE_CHECKOUT" | "DEALER_TRANSACTION_QUOTE_CREATED" | "DELIVERED" | "DIBSBOT_ADD_PRIVATE_OFFER" | "DIBSBOT_INACTIVITY_RECOMMENDATION" | "DIBSBOT_ITEM_RECOMMENDATION" | "DIBSBOT_MESSAGE_FOLLOWUP" | "DIBSBOT_PRIVATE_OFFER_FOLLOWUP" | "DIBSBOT_SIMILAR_ITEM_IN_CONV" | "FIRST_DIBS_TRANSACTION_QUOTE_DEALER_ACCEPTED" | "FIRST_DIBS_TRANSACTION_QUOTE_REQUESTED" | "HOLD_CREATED" | "HOLD_RELEASED" | "HOLD_REQUESTED" | "ITEM_RECOMMENDED" | "ITEM_TRANSFER_SOURCE" | "ITEM_TRANSFER_TARGET" | "PRE_TRANSIT" | "READY_FOR_PICKUP" | "RETURN_APPROVED" | "RETURN_COMPLETED" | "RETURN_DECLINED" | "RETURN_REQUESTED" | "REVIEW_REQUEST" | "SHIPPED" | "TRANSACTION_CONFIRMED" | "%future added value";
export type NewMilestoneSellerSubscriptionInput = {
  addSolrDelay?: boolean | null;
  buyerId?: string | null;
  filter?: ReadonlyArray<MilestoneType | null> | null;
  itemId?: string | null;
  sellerId?: string | null;
};
export type newMilestoneSellerSubscription$variables = {
  input: NewMilestoneSellerSubscriptionInput;
};
export type newMilestoneSellerSubscription$data = {
  readonly newMilestoneSeller: {
    readonly milestone: {
      readonly conversationId?: string | null;
      readonly from?: {
        readonly userType: ConversationMemberType | null;
      } | null;
      readonly textBody?: string | null;
      readonly transaction?: {
        readonly item: {
          readonly title: string | null;
        } | null;
        readonly serviceId: string | null;
      } | null;
    } | null;
    readonly milestoneNotification: {
      readonly buyerName: string | null;
      readonly serviceId: string | null;
      readonly type: MilestoneType | null;
    } | null;
  } | null;
};
export type newMilestoneSellerSubscription = {
  response: newMilestoneSellerSubscription$data;
  variables: newMilestoneSellerSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "userType",
    "value": "SELLER"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "textBody",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "MilestoneNotification",
  "kind": "LinkedField",
  "name": "milestoneNotification",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buyerName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "newMilestoneSellerSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NewMilestoneSellerSubscriptionPayload",
        "kind": "LinkedField",
        "name": "newMilestoneSeller",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "milestone",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConversationMember",
                    "kind": "LinkedField",
                    "name": "from",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Message",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Transaction",
                    "kind": "LinkedField",
                    "name": "transaction",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "item",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Milestone",
                "abstractKey": null
              }
            ],
            "storageKey": "milestone(userType:\"SELLER\")"
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscriptions",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "newMilestoneSellerSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NewMilestoneSellerSubscriptionPayload",
        "kind": "LinkedField",
        "name": "newMilestoneSeller",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "milestone",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConversationMember",
                    "kind": "LinkedField",
                    "name": "from",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Message",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Transaction",
                    "kind": "LinkedField",
                    "name": "transaction",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "item",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Milestone",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v9/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": "milestone(userType:\"SELLER\")"
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/488.0.0-2024-11-12T17:14:07.765Z/newMilestoneSellerSubscription",
    "metadata": {},
    "name": "newMilestoneSellerSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "f8b925e233d11d73310ec76de1f5380c";

export default node;
